import { useEffect, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min"
import { fetchRevenueSharingReport } from "../../../store/actions/reportActions";
import { connect } from "react-redux";
import { Button, Card, Col, Container, Modal, Row } from "react-bootstrap";
import { fetchSingleConnectAccount, stripeConfirmPaymentIntentsApi, stripePasswordValidateApi, stripePaymentIntentsApi, stripeTransferApi } from "../../../store/actions/stripeAction";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import * as Yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";


function SinglePayout(props) {
    const { location, operator } = useParams();
    const { t } = useTranslation();
    const { userid } = JSON.parse(localStorage.getItem('user'))

    const startDate1 = localStorage.getItem('startDate');
    const endDate1 = localStorage.getItem('endDate');
    const [startDate, setStartDate] = useState(new Date(startDate1));
    const [endDate, setEndDate] = useState(new Date(endDate1));
    const [invoiceId, setInvoiceId] = useState('')
    const payoutCurrency = process.env.REACT_APP_CURRENCY;
    const [stripeModalshow, setStripeModalshow] = useState(false);
    const handleStripeModalClose = () => setStripeModalshow(false);
    const handleStripeModalShow = () => setStripeModalshow(true);
    const [passwordVisible, setPasswordVisible] = useState(false);
    const on_behalf_of = process.env.REACT_APP_STRIPE_ACCOUNT_UAT;
    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    const generateSixDigitNumber = () => {
        const length = 10;
        const characters = '0123456789';
        let result = '';

        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            result += characters.charAt(randomIndex);
        }

        setInvoiceId(result);
    };

    const saveStripePasswordSchema = Yup.object().shape({
        stripe_password: Yup.string()
            .required('Stripe Password is required')
            .min(6, 'Stripe Password must be at least 6 characters')
            .max(40, 'Stripe Password must not exceed 40 characters'),
    });

    const { register: registerSecond, handleSubmit: handleSubmitSecond, getValues: getValuesSecond, setValue: setValueSecond, formState: { errors: errorsSecond } } = useForm({
        resolver: yupResolver(saveStripePasswordSchema),
    });

    const onSubmit2 = (data) => {
        props.validateStripePass(data)
    };

    useEffect(() => {
        props.revenueSharingReport(startDate.toISOString().substr(0, 10), endDate.toISOString().substr(0, 10))
        generateSixDigitNumber();
    }, []);

    const data = props.revenue_sharing_report && props.revenue_sharing_report.data
    const filterData = data && data.filter((item) => item.ID.location === location && item.ID.operator === operator)
    // console.log("payout filterData", filterData)
    const stripe_id = filterData && filterData[0]?.ID?.stripe_account
    // console.log("stripe_id", stripe_id)

    useEffect(() => {
        props.fetchSingleConnectAccount(stripe_id);
        if (stripe_id !== '') {
        }
    }, [stripe_id]);

    const singleData = props.getSingleConnectAccount && props.getSingleConnectAccount.data && props.getSingleConnectAccount.data[0]

    useEffect(() => {
        if (props.validatePass && props.validatePass.statuscode === 200) {
            props.stripePaymentIntent(filterData[0]?.ID?.client_revenue_share, singleData?.default_currency, on_behalf_of)
        }
    }, [props.validatePass])

    const id = props.stripePaymentIntentRes && props.stripePaymentIntentRes.data.id;

    useEffect(() => {
        if (props.stripePaymentIntentRes && props.stripePaymentIntentRes.statuscode === 200) {
            props.stripePaymentIntentConfirm(id)
        }
    }, [props.stripePaymentIntentRes])

    const source_transaction = props.stripePaymentIntentConfirmRes && props.stripePaymentIntentConfirmRes?.data.charges?.data[0]?.id;

    useEffect(() => {
        if (props.stripePaymentIntentConfirmRes && props.stripePaymentIntentConfirmRes.statuscode === 200) {
            props.stripeTransfer(filterData[0]?.ID?.client_revenue_share, singleData?.default_currency, stripe_id, source_transaction)
        }
    }, [props.stripePaymentIntentConfirmRes])

    if (props.validatePass.statuscode === 200 && props.stripePaymentIntentRes.statuscode === 200 && props.stripePaymentIntentConfirmRes.statuscode === 200 && props.stripeTransferRes.statuscode === 200) {
        alert("Payout transfer completed successfully")
        window.location.reload();
    }

    useEffect(() => {
        if (props.validatePass && props.validatePass.statuscode === 400) {
            alert(props.validatePass.error)
            window.location.reload();
        }
        if (props.stripePaymentIntentRes && props.stripePaymentIntentRes.statuscode === 405) {
            alert(props.stripePaymentIntentRes.error)
            window.location.reload();
        }
        if (props.stripePaymentIntentConfirmRes && props.stripePaymentIntentConfirmRes.statuscode === 405) {
            alert(props.stripePaymentIntentConfirmRes.error)
            window.location.reload();
        }
        if (props.stripeTransferRes && props.stripeTransferRes.statuscode === 405) {
            alert(props.stripeTransferRes.error)
            window.location.reload();
        }
    }, [props.validatePass, props.stripePaymentIntentRes, props.stripePaymentIntentConfirmRes, props.stripeTransferRes])

    return (
        <h3>
            <Container className="py-5">
                <Card className="invoicepayoutcard">
                    {/* <Card.Body> */}
                    <Row className="d-flex justify-content-center">
                        <Col lg={12}>
                            <Row className="invoice_header p-2">
                                <Col lg={3} md={6} className="">
                                    <Row className='d-flex justify-content-center '>
                                        <img className='' src={process.env.PUBLIC_URL + '/images/c_logo.png'} alt='Gaadin logo' />
                                    </Row>
                                    <Row className='d-flex justify-content-center  mt-2'>
                                        <h3>{t("Invoice")}</h3>
                                    </Row>
                                </Col>
                                <Col lg={9} md={6} className="d-flex justify-content-end align-items-end">
                                    <ul className="custom-list">
                                        <li>
                                            <b>{t("Company Name")} -</b> <span>{process.env.REACT_APP_CLIENT_NAME}</span>
                                        </li>
                                        <li>
                                            <b>{t("State")} -</b>
                                            <span style={{ fontWeight: "100" }}>{process.env.REACT_APP_CLIENT_STATE}</span>
                                        </li>
                                        <li>
                                            <b>{t("City")} -</b>
                                            <span style={{ fontWeight: "100" }}>{process.env.REACT_APP_CLIENT_CITY}</span>
                                        </li>
                                        <li>
                                            <b>{t("Pincode")} -</b>
                                            <span style={{ fontWeight: "100" }}>{process.env.REACT_APP_CLIENT_PINCODE}</span>
                                        </li>
                                    </ul>
                                </Col>
                            </Row>
                            <Row className="middle_section">
                                <Col lg={6} className="">
                                    <ul className="custom-list pl-3">
                                        <li><h4>{t("Bill To")} :</h4></li>
                                        <li><b>{("Email")} :</b> <span style={{ fontWeight: "100" }}>{singleData && singleData.email}</span></li>
                                        <li><b>{t("Account ID")} :</b> <span style={{ fontWeight: "100" }}>{stripe_id}</span></li>
                                    </ul>
                                </Col>
                                <Col lg={6} className="d-flex justify-content-end align-items-end pr-3">
                                    <ul className="custom-list">
                                        <li><b>{t("Invoice No")} :</b> #<span style={{ fontWeight: "100" }}>{invoiceId}</span></li>
                                        <li>
                                            <b>{t("Date")} :  &nbsp;</b>
                                            <span>{t("From")}</span>
                                            &nbsp;
                                            -
                                            &nbsp;
                                            <span>{t("To")}</span>
                                        </li>
                                        <li><span style={{ fontWeight: "100" }}>{moment(startDate).format('DD-MM-YYYY')}</span>&nbsp;   -  &nbsp; <span style={{ fontWeight: "100" }}>{moment(endDate).format('DD-MM-YYYY')}</span></li>
                                    </ul>
                                </Col>
                            </Row>
                            <Row className="bottom_section">
                                <Col lg={12}>
                                    <ul className="custom-list pl-0">
                                        <li>
                                            <b>{t("Description")}</b>
                                        </li>
                                        <li>
                                            <b>{t("Billing for location")} : </b>
                                            <span style={{ fontWeight: "100" }}>
                                                {filterData[0]?.ID?.location}
                                            </span>
                                        </li>
                                        <li>
                                            <b>{t("Billed Unit")} : </b>
                                            <span style={{ fontWeight: "100" }}>{filterData[0]?.kwh}</span>
                                            <span style={{ fontWeight: "100" }}>kWh </span>
                                        </li>
                                    </ul>
                                </Col>
                            </Row>
                            <Row className="footer_section">
                                <Col lg={12} className="d-flex justify-content-end">
                                    <ul className="custom-list pl-0">
                                        <li className="text-right">
                                            <b>{t("Total")}</b>
                                        </li>
                                        <li className="text-right">
                                            <span style={{ fontWeight: "100" }}>
                                                {payoutCurrency} {filterData[0]?.ID?.client_revenue_share}
                                            </span>
                                        </li>
                                        <li >
                                            <span style={{ fontWeight: "100" }}>
                                                <Col xs={12} lg={12} style={{ fontWeight: 600, padding: "0px" }}>
                                                    <button className="btn  commandButtons" style={{ fontSize: "15px", padding: "5px" }} type="button" onClick={handleStripeModalShow}>
                                                        {t("Procced to payout")}
                                                    </button>
                                                    <form onSubmit={e => e.preventDefault()}>
                                                        <Modal show={stripeModalshow} onHide={handleStripeModalClose}>
                                                            <Modal.Header closeButton>
                                                                <Modal.Title>{t("Transaction Password for")} <b>{userid}</b></Modal.Title>
                                                            </Modal.Header>
                                                            <Modal.Body>
                                                                <div className="form-floating">
                                                                    <label className='mt-1' htmlFor="floatingInputGridstripe_password">{t("Stripe Transaction Password")}</label>
                                                                    <input type={passwordVisible ? "text" : "password"} className={`form-control gray-border ${errorsSecond.stripe_password ? 'is-invalid' : ''}`}
                                                                        name="stripe_password"
                                                                        id="floatingInputGridstripe_password"
                                                                        placeholder={t("Enter Stripe Password")}
                                                                        {...registerSecond("stripe_password")}
                                                                    />
                                                                    <span className="p-viewer-payout-stripepass">
                                                                        <i className="btn" onClick={togglePasswordVisibility}>
                                                                            {passwordVisible ? <AiFillEye /> : <AiFillEyeInvisible />}
                                                                        </i>
                                                                    </span>
                                                                    <div className="invalid-feedback">{errorsSecond.stripe_password?.message}</div>
                                                                </div>
                                                                <br />
                                                            </Modal.Body>
                                                            <Modal.Footer>
                                                                <Button variant="secondary" onClick={handleStripeModalClose}>
                                                                    {t("Close")}
                                                                </Button>
                                                                {props.loading ?
                                                                    <Button variant="primary" disabled>
                                                                        <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                                                        {t("Saving...")}
                                                                    </Button>
                                                                    :
                                                                    <Button variant="primary" onClick={handleSubmitSecond(onSubmit2)}>{t("Submit")}
                                                                    </Button>
                                                                }
                                                            </Modal.Footer>
                                                        </Modal>
                                                    </form>
                                                </Col>
                                            </span>
                                        </li>
                                    </ul>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    {/* </Card.Body> */}
                </Card>
            </Container>
        </h3>
    )

}

const mapStateToProps = (state) => {
    return {
        loading: state.stripe.isLoading,
        revenue_sharing_report: state.report.revenueSharingList,
        getSingleConnectAccount: state.stripe.getSingleAcountInfo,
        validatePass: state.stripe.stripePasswordValidations,
        stripeTransferRes: state.stripe.stripeTransfers,
        stripePaymentIntentRes: state.stripe.stripePaymentIntents,
        stripePaymentIntentConfirmRes: state.stripe.stripeConfirmPaymentIntents,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        revenueSharingReport: (start, end) => dispatch(fetchRevenueSharingReport(start, end)),
        fetchSingleConnectAccount: (id) => dispatch(fetchSingleConnectAccount(id)),
        validateStripePass: (data) => dispatch(stripePasswordValidateApi(data)),
        stripePaymentIntent: (amount, currency, on_behalf_of) => dispatch(stripePaymentIntentsApi(amount, currency, on_behalf_of)),
        stripePaymentIntentConfirm: (id) => dispatch(stripeConfirmPaymentIntentsApi(id)),
        stripeTransfer: (amount, currency, stripe_account, source_transaction) => dispatch(stripeTransferApi(amount, currency, stripe_account, source_transaction)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(SinglePayout);
