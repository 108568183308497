import React, { useEffect, useState } from "react";
import "react-dates/initialize";
import { useMemo } from "react";
import "react-datepicker/dist/react-datepicker.css";
import Table from "../../reacttable/table";
import moment from "moment";
import { FaEdit } from "react-icons/fa";
import { connect } from "react-redux";
import CustomReportPaginationTable from "../../report/adminReport/customReportPaginationTable";
import { useTranslation } from "react-i18next";
import { customerFeedback, customerFeedbackAllData } from "../../../../store/actions/customerActions";


function CustomerFeedbackTable(props) {
  const { t } = useTranslation();
  const document_count = props.customerFeedback && props.customerFeedback?.document_count;
  const defaultPageSize = 10;
  const pageCount = Math.ceil(document_count / defaultPageSize)
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredData, setFilteredData] = useState([]);
  const [search, setSearch] = useState('');
  const [TableData,setTableData] = useState("");
  

useEffect(() => {
    props.getCustomerFeedbackTable(currentPage, 10)
}, [currentPage])

const data = props.customerFeedback && props.customerFeedback.data;



  useEffect(() => {
    if (search !== "" && TableData == "") {
      const id = setTimeout(() => {
        props.getAllData(1, document_count)
      }, 2000);
      return () => clearTimeout(id);
    }
  }, [search]);

  useEffect(()=>{
    if (props.customerFeedbackall && props.customerFeedbackall.data) {
      setTableData(props.customerFeedbackall.data);
    }
  }, [props.customerFeedbackall])

  const columns = useMemo(
    () => [

      {
        Header: "Station Name",
        accessor: "stationid",
      },
      {
        Header: "User Name",
        accessor: "userid",
        id: "useridd",
      },
      {
        Header: "Operator",
        accessor: "operator",
      },
      {
        Header: "Created Date",
        id: "createat",
        accessor:"createat",
        Cell: d => {
          return moment(d.createdat).format("DD-MM-YYYY");
        },
      },
      {
        Header: "Comments",
        accessor: "comment",
      },
      {
        Header: "Rating",
        accessor: "rating",
      },
      {
        Header: "Status",
        id: "status",
        accessor: "status",
        Cell: d => {
          let statusColorClass = "";
          if (d.cell === "Open") {
            statusColorClass = "text-success";
          } else if (d.status === "In-review") {
            statusColorClass = "text-primary";
          } else if (d.status === "Closed") {
            statusColorClass = "text-danger";
          }
          return d.cell ? <span className={`${statusColorClass} pallet`} style={{ userSelect: "none" }} ><b>{d.cell}</b></span> : "";
        },
      },

      {
        Header: "Admin Feedback",
        accessor: "admin_comment",
      },
      {
        Header: 'Actions',
        accessor: 'id',
        Cell: (id) => {          
          return (
            <div>
              <span>
                <a
                  href={`/dashboard/accountcard/adminstatus/${id.cell}`}
                  rel="tooltip"
                  title="Edit"
                >
                  <FaEdit />
                </a>
              </span>

            </div>

          );
        },
      }

    ],
    []
  );

  const initialVisibleColumns = [
    "stationid","userid","operator","createat","comment","rating","status","admin_comment","id"
  ]

  return (
    <>
      <div className="customercard card mt-3">
                              <CustomReportPaginationTable
                                data={data}
                                allData={TableData}
                                pageCount={pageCount}
                                columns={columns}
                                search={search}
                                setSearch={setSearch}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                pageSize={pageSize}
                                setPageSize={setPageSize}
                                filteredData={filteredData}
                                setFilteredData={setFilteredData}
                                loading={props.loading}
                                initialVisibleColumns={initialVisibleColumns}
                                t={t}
                            />
        {/* <Table data={feedbackTableData} columns={columns} ></Table> */}
      </div>
    </>
  )
}
const mapStateToProps = (state) => {
  return {
    loading: state.customer.isLoading,
    customerFeedback: state.customer.customerFeedback,
    customerFeedbackall:state.customer.customerFeedbackall
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getCustomerFeedbackTable: (currentPage, pageSize) => dispatch(customerFeedback(currentPage, pageSize)),
    getAllData :(currentPage, pageSize) => dispatch(customerFeedbackAllData(currentPage, pageSize)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CustomerFeedbackTable);