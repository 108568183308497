import React, { useEffect, useRef } from "react";
import { Row, Col, Card, Container } from "react-bootstrap";
import "react-dates/initialize";
import { useMemo, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import { CSVLink } from "react-csv";
import "../report/report.css";
import moment from "moment";
import AdminTable from "./adminReport/adminreportTable";
import PureBreadcrumbs from "../breadcrums";
import { fetchAllPlugUnplugsReportDataAction, fetchPlugUnplugsReport } from "../../../store/actions/reportActions";
import { allOrgTariffAction } from "../../../store/actions/organisationAction";
import { useTranslation } from "react-i18next";
import CustomReportPaginationTable from "./adminReport/customReportPaginationTable";


function PlugUnplugReport(props) {
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState(new Date(Date.now() - 3600 * 1000 * 720));
  const [endDate, setEndDate] = useState(new Date(Date.now() + 3600 * 1000 * 24));
  const { role, organisation } = JSON.parse(localStorage.getItem('user'));
  const [org, setOrg] = useState("All");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const document_count = props.PUlist && props.PUlist.document_count;
  const defaultPageSize = 10;
  const pageCount = Math.ceil(document_count / defaultPageSize)
  const [csvDownload, setCsvDownload] = useState(false);
  const csvLinkRef = useRef(null);
  const [csvdata,setCsvdata] = useState("");
  const { allData } = props;

  // console.log(organisation)

  const handleChange = (e) => {
    const data = e.target.value;
    setOrg(data);
  };

  useEffect(() => {
    if (role === 'Admin') {
      props.list(startDate?.toISOString()?.substr(0, 10), endDate?.toISOString()?.substr(0, 10), org, currentPage, pageSize);
      // props.fetchAllPlugUnplugsReportDataApi(startDate.toISOString().substr(0, 10), endDate.toISOString().substr(0, 10), org, 1, 10000000);
    } else {
      props.list(startDate.toISOString()?.substr(0, 10), endDate?.toISOString()?.substr(0, 10), organisation, currentPage, pageSize);
      // props.fetchAllPlugUnplugsReportDataApi(startDate.toISOString().substr(0, 10), endDate.toISOString().substr(0, 10), organisation, 1, 10000000);
    }
    props.getOrg()
  }, [role, currentPage, pageSize])

  const loadNewData = () => {
    if (role === 'Admin') {
      props.list(startDate?.toISOString()?.substr(0, 10), endDate?.toISOString()?.substr(0, 10), org, currentPage, pageSize);
      // props.fetchAllPlugUnplugsReportDataApi(startDate.toISOString().substr(0, 10), endDate.toISOString().substr(0, 10), org, 1, 10000000);
    } else {
      props.list(startDate?.toISOString()?.substr(0, 10), endDate?.toISOString()?.substr(0, 10), organisation, currentPage, pageSize);
      // props.fetchAllPlugUnplugsReportDataApi(startDate.toISOString().substr(0, 10), endDate.toISOString().substr(0, 10), organisation, 1, 10000000);
    }
  };

  const reportData = props.PUlist && props.PUlist.data;
  
  useEffect(()=>{
    if (props.fetchAllPlugUnplugRes && props.fetchAllPlugUnplugRes.data) {
      setCsvdata(props.fetchAllPlugUnplugRes.data);
    }
  }, [props.fetchAllPlugUnplugRes])
  
  useEffect(() => {
    if (search !== "" && csvdata=="" ) {
      const id = setTimeout(() => {
        if (role === 'Admin') {
          props.fetchAllPlugUnplugsReportDataApi(startDate?.toISOString()?.substr(0, 10), endDate?.toISOString()?.substr(0, 10), org, 1, 10000000);
        } else {
          props.fetchAllPlugUnplugsReportDataApi(startDate?.toISOString()?.substr(0, 10), endDate?.toISOString()?.substr(0, 10), organisation, 1, 10000000);
        }
      }, 3000);
      return () => clearTimeout(id);
    }
  }, [search, startDate, endDate, org,organisation]);

  const loadCsvData = () => {
      if (role === 'Admin') {
        props.fetchAllPlugUnplugsReportDataApi(startDate?.toISOString()?.substr(0, 10), endDate?.toISOString()?.substr(0, 10), org, 1, 10000000);
      } else {
        props.fetchAllPlugUnplugsReportDataApi(startDate?.toISOString()?.substr(0, 10), endDate?.toISOString()?.substr(0, 10), organisation, 1, 10000000);
      }
      setCsvDownload(true);
  }

  useEffect(()=>{
    if(props.fetchAllPlugUnplugRes?.statuscode === 200 && csvDownload){
     const id = setTimeout(() => {
        csvLinkRef.current?.link?.click();
        setCsvDownload(false);
      setCsvdata("")
      }, 1000);
      return () => clearTimeout(id);
    }
  },[props.csvDataRes,csvdata])


  const columns = useMemo(
    () => [
      { Header: "Evse Id", accessor: "evse_id", id: "evse_idd" },
      {
        Header: 'Date',
        id: 'date',
        accessor: 'date',
      },
      { Header: "Name", accessor: "name" },
      { Header: "Address", accessor: "address" },
      { Header: "Status A", accessor: "status_a" },
      { Header: "Status B", accessor: "status_b" },
      { Header: "Status C", accessor: "status_c" },
      { Header: "City", accessor: "city" },
      { Header: "Plug Time", accessor: "plug_time" },
      { Header: "UnPlug Time", accessor: "unplug_time" },
      { Header: "Charging time", accessor: "charging_time" },
      { Header: "Operator", accessor: "operator" },
    ],
    []
  );

  const headers = [
    { label: "Evse ID", key: "evse_id" },
    { label: "Date", key: "date", Cell: (value) => moment(value).format('DD-MM-YYYY') },
    { label: "Name", key: "name" },
    { label: "Address", key: "address" },
    { label: "Status A", key: "status_a" },
    { label: "Status B", key: "status_b" },
    { label: "Status C", key: "status_c" },
    { label: "City", key: "city" },
    { label: "Plug Time", key: "plug_time" },
    { label: "UnPlug time", key: "unplug_time" },
    { label: "Charging time", key: "charging_time" },
    { label: "Operator", key: "operator" },
  ];

  const initialVisibleColumns =
    [
      'evse_id', 'date', 'name', 'address', 'status_a',
      'status_b', 'status_c', 'city', 'plug_time', 'unplug_time',
      'charging_time', 'operator'
    ];

  return (
    <Container fluid >
      <PureBreadcrumbs />
      <Row className="mt-4">
        <Col>
          <Card className="customercard mt-4">
            <Card.Header>
              <Row>
                <Col md={2} sm={12}>
                  {role} {t("Plug & Unplug Report List")}
                </Col>
                <Col md={10} sm={12}>
                  <div className="row justify-content-end">
                    {role === "Admin" ? <div style={{ paddingRight: "10px" }} >
                      <select style={{ borderRadius: "3px", borderColor: "#a7a6a6" }} className={``} onChange={(e) => handleChange(e)}>
                        <option value={"All"} >
                          {t("All")}
                        </option>
                        {props.orgdata && props.orgdata.organisations && props.orgdata?.organisations?.map((v, k) => (
                          <option key={k} value={v.name}>
                            {v.name}
                          </option>
                        ))}
                      </select>
                    </div>
                      :
                      ""
                    }
                    <div className="row justify-content-center">
                      <div className="col-auto claender">
                        <DatePicker
                          className="gray-border"
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                          selectsStart
                          startDate={startDate}
                          dateFormat="dd/MM/yyyy"
                          maxDate={new Date()}
                        />
                      </div>
                      <span style={{ paddingTop: "7px" }}> To </span>
                      <div className="col-auto">
                        <DatePicker
                          className="gray-border"
                          selected={endDate}
                          onChange={(date) => setEndDate(date)}
                          selectsEnd
                          startDate={startDate}
                          endDate={endDate}
                          minDate={startDate}
                          showDisabledMonthNavigation
                          dateFormat="dd/MM/yyyy"
                          maxDate={new Date()}
                        />
                      </div>
                    </div>
                    <div className="csvbutton ml-3 ">
                      <button className="btn btn-sm" onClick={loadNewData}>
                        <span
                          style={{
                            fontSize: "16px",
                            color: "#676767",
                            fontWeight: "600",
                          }}
                        >
                          GO
                        </span>
                      </button>
                    </div>
                    <div className="mt-1">
                      {csvDownload  ? (
                        <CSVLink
                          data={csvdata}
                          headers={headers}
                          filename={
                            org +
                            "_" +
                            startDate?.toISOString()?.substr(0, 10) +
                            "_" +
                            endDate?.toISOString()?.substr(0, 10) + " " +
                            `Plug-UnPlug Report.csv`
                          }
                          target="_blank"
                          ref={csvLinkRef}
                        >
                          <i className="fas fa-2x fa-file-csv"></i>
                        </CSVLink>
                      ) : (
                        <div  onClick={loadCsvData}>
                          <i className="fas fa-2x fa-file-csv"></i>
                          </div>
                      )}
                    </div>
                    {/* <Col className="col-md-10 col-sm-12 d-flex justify-content-end mt-3">

                      <div style={{ paddingRight: "15px" }}>
                        {props.all && props.all.data != null ? (
                          <MyDocument
                            {...props}
                            org={org}
                            start={startDate}
                            end={endDate}
                          ></MyDocument>
                        ) : (
                          <i className="fas fa-file-pdf"></i>
                        )}
                      </div>
                      <div>
                        {props.all && props.all.data != null ? (
                          <VendorInvoiceDocument
                            {...props}
                            org={org}
                            start={startDate}
                            end={endDate}
                          ></VendorInvoiceDocument>
                        ) : (
                          <i className="fas fa-file-pdf"></i>
                        )}
                      </div>
                    </Col> */}
                  </div>
                </Col>
              </Row>
            </Card.Header>
            <div className="evse">
              {props.loading ? <h3 style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "250px",
              }}>Loading data...</h3> :
                props.PUlist && props.PUlist.data != null ? (
                  <div>
                    {/* <AdminTable data={reportData} columns={columns}></AdminTable> */}
                    <CustomReportPaginationTable
                      data={reportData}
                      allData={csvdata}
                      pageCount={pageCount}
                      columns={columns}
                      search={search}
                      setSearch={setSearch}
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      pageSize={pageSize}
                      setPageSize={setPageSize}
                      filteredData={filteredData}
                      setFilteredData={setFilteredData}
                      loading={props.loading}
                      initialVisibleColumns={initialVisibleColumns}
                      t={t}
                    />
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "250px",
                    }}
                  >
                    <h3>No Data Found</h3>
                  </div>
                )}
            </div>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.report.isLoading,
    PUlist: state.report.plugUnplug,
    fetchAllPlugUnplugRes: state.report.fetchAllPlugUnplug,
    orgdata: state.operator.organisationsList,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    list: (start, end, org, currentPage, pageSize) => dispatch(fetchPlugUnplugsReport(start, end, org, currentPage, pageSize)),
    fetchAllPlugUnplugsReportDataApi: (start, end, org, currentPage, pageSize) => dispatch(fetchAllPlugUnplugsReportDataAction(start, end, org, currentPage, pageSize)),
    getOrg: () => dispatch(allOrgTariffAction())
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PlugUnplugReport);
