import React from 'react';
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useTranslation } from 'react-i18next';

// Create Document Component
const TransactionReportPdf = (props) => {
    const { t } = useTranslation();
    // const gstNumber = process.env.REACT_APP_CLIENT_GST_NO;
    // console.log("props: ", props)
    const generatePDF = () => {
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "landscape"; // portrait or landscape
        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);
        doc.setFontSize(12);
        const org_name = props.org === "All" ? "All" : props.org
        // const range = "Date range: " + props.start.toLocaleString() + " to " + props.end.toLocaleString()
        const Organisation = "Organisation: " + org_name;
        // const v_amt = (props.csvDataRes?.total_revenue)
        // const tax = ((v_amt * 15) / 100)
        // const org_unit = "Consumed kWh: " + props.reportCountRes?.total_energy
        // const rfid_free_charging_energy = "Free charging consumed kWh: " + props.reportCountRes?.free_charging_total_energy
        // const org_amt = "Total Amount: " + v_amt.toFixed(2)
        // const org_tax = "Tax Amount: " + tax.toFixed(2)
        // const total_amt = "Total Payable(Amount + Tax): " + "$" + (v_amt + tax).toFixed(2);
        // const free_charging_energy = props.reportCountRes?.free_charging_total_energy;
        // const total_kwh = `Total kWh ${(props.reportCountRes?.total_energy - free_charging_energy).toFixed(2)}`;
        // const gst_number = "GST Number: " + gstNumber;

        const headers = [[
            "ID",
            "First Name",
            "Last Name",
            "User Name",
            "EVSE ID",
            "Location",
            "Date",
            "Start Time",
            "End Time",
            "Connector ID",
            "kWh",
            "Cost Excluding Vat",
            "Cost Including Vat",
            "Status"
        ]];

        const data = props.processedData?.map(elt =>
            [
                elt.charging_id,
                elt.firstname,
                elt.lastname,
                elt.userid,
                elt.evse_uid,
                elt.location_id,
                elt.date,
                elt.start_date_time,
                elt.end_date_time,
                elt.connector_id,
                elt.kwh,
                elt.excl_vat,
                elt.incl_vat,
                elt.status
            ]);
            
        let content = {
            startY: 60,
            head: headers,
            body: data
        };

        doc.text(Organisation, marginLeft, 40);
        doc.autoTable(content);
        doc.save(props.org + `OCPI Transaction Report.pdf`)
    }

    const handleButtonClick = async () => {
        // Generate the PDF
        generatePDF();
        // props.onClick();
    };

    return (
        <div>
            <button onClick={handleButtonClick} className='pdfbutton' disabled={!props.processedData?.length > 0} type="button">{t("PDF(Partner)")}</button>
        </div>
    )
}

export default TransactionReportPdf;