import React from 'react'
import { useForm, useFieldArray } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useState, useEffect } from "react";
import { Card, Row, Col } from "react-bootstrap";
import { connect } from 'react-redux';
import { fetchActiveTariffList, singleTimeSlotTariff, updateNewTimeSlotTariff } from '../../../../store/actions/tariffAction';
import { allEvseAction, fetchTimeMasterListAction, fetchaAllDaysListAction } from '../../../../store/actions/evseAction';
import { Redirect, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useTranslation } from 'react-i18next';
import PureBreadcrumbs from "../../breadcrums";
import { Bounce, ToastContainer, toast } from 'react-toastify';


const UpdateDynamicTariff = (props) => {
    const [selectedStartTimeIndex, setSelectedStartTimeIndex] = useState([]);
    const { id } = useParams()
    const { t } = useTranslation();
    const [redirect, setRedirect] = useState(false);
    const [isToastVisible, setIsToastVisible] = useState(false);

    useEffect(() => {
        props.getTimeMaster();
        props.getActiveTariffList();
        props.getSingleTariffData(id);
        props.all();
        props.getAllDayMaster();
    }, [])

    const priceSchema = Yup.object().shape({
        station_name: Yup.string().required("Station Name is required"),
        plan_name: Yup.string().required('Plan Name is required'),
        timeslots: Yup.array().of(
            Yup.object().shape({
                day: Yup.string().required('Day is required'),
                start_time: Yup.string().required('Start From is required'),
                end_time: Yup.string().required('End To is required'),
                tariff_name: Yup.string().required('Tariff name is required'),
            })),
    });

    const { register, control, setValue, getValues, watch, handleSubmit, formState: { errors } } = useForm({ resolver: yupResolver(priceSchema), mode: "all" });
    const { fields: fieldsList1, remove: removeList1, append: appendList1 } = useFieldArray({ control, name: "timeslots" });
    const elementsTime = watch("timeslots");
    // console.log(elementsTime)

    const data = props.fetchSingleTariffDetails && props.fetchSingleTariffDetails.data
    // console.log(data);

    useEffect(() => {
        const { timeslot, station_name, plan_name } = data;
        if (data) {
            setValue("timeslots", timeslot, { shouldValidate: true });
            setValue("station_name", station_name, { shouldValidate: true });
            setValue("plan_name", plan_name, { shouldValidate: true });
        }
    }, [data], setValue)



    const handleChange = (e, i) => {
        const selectedIndex = e.target.selectedIndex;
        setSelectedStartTimeIndex((prev) => {
            const newStartTimes = [...prev];
            newStartTimes[i] = selectedIndex;
            return newStartTimes;
        });
    }

    // useEffect(()=>{
    //     if (elementsTime && selectedStartTime) {
    //         const timeMatching = selectedStartTime.every(item =>
    //             elementsTime.some(connector => connector.evse_id === id)
    //         );
    //         // console.table(chargersWithConnectors)
    //         setIsMatching(timeMatching);
    //     }
    // },[elementsTime,selectedStartTime])

    const onSubmit = async data => {
        props.saveTimeSlot(data, id)
        // console.log(data)
    };

    useEffect(() => {
        if (props.save.statuscode === 200) {
            setIsToastVisible(true);
            toast.success('Updated Time slot successfully', {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
                onClose: () => {
                    setIsToastVisible(false);
                    setRedirect(true)
                },
            });
        }
    }, [props.save]);

    if (redirect) {
        return <Redirect to='/dashboard/view_time_slot' />;
    }

    return (
        <>
            {isToastVisible && <div className="overlay" />}
            <ToastContainer
                position="top-center"
                autoClose={20}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                style={{ top: "10%", width: "auto", zIndex: "9999" }}
            />

            <div className='container-fluid'>
                <PureBreadcrumbs />
                <Row className='d-flex justify-content-center align-items-center pt-5'>
                    <Col xs={12} md={12} lg={11}>
                        <form autoComplete="off" onSubmit={e => e.preventDefault()}>
                            <div>
                                <Card className='d-flex justify-content-center align-items-center'>
                                    <Card.Body>
                                        <h6> {t("Update Tariff Time Slots")}</h6>
                                    </Card.Body>
                                </Card>
                                <Row className='tariffcard ml-0 mr-0'>
                                  {props.list &&  <Col lg={6}>
                                        <div className="form-floating">
                                            <label>{t("Select Station Name")}<span className='mandatory-field'>*</span></label>
                                            <select disabled autoFocus className={`form-select form-control ${errors.station_name ? 'is-invalid' : ''}`} name={`station_name`} id="floatingSelectGridstation_name"  {...register(`station_name`)}
                                            // onBlur={handleBlur}
                                            >
                                                <option value={""}>{t("Select Station Name")}</option>
                                                {props.list?.data?.map((v, k) => (
                                                        <option key={v.stationid} value={v.name}>
                                                            {v.name}
                                                        </option>
                                                    ))}
                                            </select>
                                        </div>
                                        <div className="invalid-feedback">{errors.station_name?.message}</div>
                                    </Col>}
                                    <Col lg={6}>
                                        <div className="">
                                            <div className="form-floating">
                                                <label>{t("Plan Name")}<span className='mandatory-field'>*</span></label>
                                                <input type="text" className={`form-control gray-border ${errors.plan_name ? 'is-invalid' : ''}`} name={`plan_name`} id="floatingInputplan_name" placeholder={t("Plan Name")} {...register(`plan_name`)} />
                                                <div className="invalid-feedback">{errors.plan_name?.message}</div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>

                                {fieldsList1.map((item, i) => (
                                    <Card className='tariff-card' key={i}>
                                        {i === 0 ? <h6 className='text-start p-3'><b>{t("Update Time Slots")}</b></h6> : ''}
                                        <Card.Body>
                                            <div className="row g-2 mt-3 ">
                                                <div className="col-md">
                                                    <div className="form-floating">
                                                        <label>{t("Select days")}<span className='mandatory-field'>*</span></label>
                                                        <select className={`form-select form-control ${errors.timeslots?.[i]?.day ? 'is-invalid' : ''}`} name={`timeslots[${i}].day`} id="floatingSelectGridday"  {...register(`timeslots[${i}].day`)}>
                                                            <option value={""}>{t("Select days")}</option>
                                                            {props.fetchDayMaster && props.fetchDayMaster.days?.map(day => (
                                                                <option key={day.key} value={day.value}>{day.key}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    <div className="invalid-feedback">{errors.timeslots?.[i]?.day?.message}</div>
                                                </div>

                                                <div className="col-md">
                                                    <div className="form-floating">
                                                        <label>{t("From")}<span className='mandatory-field'>*</span></label>
                                                        <select className={`form-select form-control ${errors.timeslots?.[i]?.start_time ? 'is-invalid' : ''}`} name={`timeslots[${i}].start_time`} id="floatingSelectGridperiod_end"  {...register(`timeslots[${i}].start_time`)}
                                                            onChange={(e) => handleChange(e, i)} >
                                                            <option value={""}>{t("Select Start Time")}</option>
                                                            {props.mastertimelist && props.mastertimelist.hours && props.mastertimelist.hours.map((v, k) =>
                                                                <option key={v.key} value={v.value}>{v.value}</option>
                                                            )}
                                                        </select>
                                                    </div>
                                                    <div className="invalid-feedback">{errors.timeslots?.[i]?.start_time?.message}</div>
                                                </div>

                                                <div className="col-md">
                                                    <div className="form-floating">
                                                        <label>{t("To")}<span className='mandatory-field'>*</span></label>
                                                        <select className={`form-select form-control ${errors.timeslots?.[i]?.end_time ? 'is-invalid' : ''}`} name={`timeslots[${i}].end_time`} id="floatingSelectGridperiod_end"  {...register(`timeslots[${i}].end_time`)}>
                                                            <option value={""} >{t("Select End Time")}</option>
                                                            {props.mastertimelist && props.mastertimelist?.hours && props.mastertimelist?.hours?.slice(selectedStartTimeIndex[i],).map((v, k) =>
                                                                <option key={v.key} value={v.value}>{v.value}</option>
                                                            )}
                                                        </select>
                                                    </div>
                                                    <div className="invalid-feedback">{errors.timeslots?.[i]?.end_time?.message}</div>
                                                </div>

                                                <div className="col-md">
                                                    <div className="form-floating">
                                                        <label>{t("Select Tariff")}<span className='mandatory-field'>*</span></label>
                                                        <select className={`form-select form-control ${errors.timeslots?.[i]?.tariff_name ? 'is-invalid' : ''}`} name={`timeslots[${i}].tariff_name`} id="floatingSelectGridtariff_name"  {...register(`timeslots[${i}].tariff_name`)}>
                                                            <option value={getValues(`timeslots[${i}].tariff_name`)}>{getValues(`timeslots[${i}].tariff_name`)}</option>
                                                            {props.fetchActiveTariffList && props.fetchActiveTariffList.data && props.fetchActiveTariffList.data.map((v, k) =>
                                                                <option key={v.id} value={v.name}>{v.name}</option>
                                                            )}
                                                        </select>
                                                    </div>
                                                    <div className="invalid-feedback">{errors.timeslots?.[i]?.tariff_name?.message}</div>
                                                </div>
                                            </div>
                                        </Card.Body>
                                        <div className="d-grid gap-2 d-flex justify-content-end p-1">
                                            <button className="btn  btn-lg" type="button" onClick={(e) => { e.preventDefault(); removeList1(i); }}> <i className="far fa-trash-alt" style={{ "color": "red" }}></i></button>
                                        </div>
                                    </Card>
                                ))}

                                <Card.Footer className="d-grid gap-2 ">
                                    <Row className='d-flex justify-content-between'>

                                        <Col lg={3} md={3} sm={4} xs={12} className="mt-2">
                                            <button className="btn btn-dark" type="button" onClick={() => appendList1({ plan_name: '', day: '', start_time: '', end_time: '', tariff_name: '' })}> (+) {t("Update Time Slots")}</button>
                                        </Col>
                                        {elementsTime && elementsTime.length > 0 &&
                                            <Col lg={3} md={3} sm={4} xs={12} className="d-md-flex justify-content-end mt-2">
                                                {props.loading ?
                                                    <button className="btn lgn-btn" type="button" disabled>
                                                        <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                                        &nbsp;
                                                        {t("Saving...")}
                                                    </button>
                                                    :
                                                    <button
                                                        className="lgn-btn btn"
                                                        type="submit"
                                                        onClick={handleSubmit(onSubmit)}
                                                    >
                                                        {t("Submit")}
                                                    </button>
                                                }
                                            </Col>
                                        }
                                    </Row>
                                </Card.Footer>

                            </div>
                        </form>
                    </Col>
                </Row>
            </div>

        </>
    )
}


const mapStateToProps = (state) => {
    return {
        res: state.tariff.createtariff,
        loading: state.tariff.isLoading,
        mastertimelist: state.evse.timemaster,
        fetchActiveTariffList: state.tariff.fetchActiveTariffName,
        fetchSingleTariffDetails: state.tariff.fetchSingleTimeSlot,
        save: state.tariff.updateTimeSlot,
        list: state.evse.allevse,
        fetchDayMaster: state.evse.fetchAllDaysMaster,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        all: () => dispatch(allEvseAction()),
        getActiveTariffList: () => dispatch(fetchActiveTariffList()),
        getTimeMaster: () => dispatch(fetchTimeMasterListAction()),
        getSingleTariffData: (id) => dispatch(singleTimeSlotTariff(id)),
        saveTimeSlot: (data, id) => dispatch(updateNewTimeSlotTariff(data, id)),
        getAllDayMaster: () => dispatch(fetchaAllDaysListAction()),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(UpdateDynamicTariff)