import { Container, Row, Col, Card } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { useParams, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { singleCutomersAction, updateCustomerProfile } from "../../../../store/actions/customerActions";
import PureBreadcrumbs from "../../breadcrums";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import PlacesAutocomplete, { geocodeByAddress, getLatLng, } from 'react-places-autocomplete';
import Geocode from "react-geocode";
import { t } from "i18next";
import { Bounce, ToastContainer, toast } from "react-toastify";
import { useLoadScript } from "@react-google-maps/api";
const libraries = ["places"];

function UpdateCustmomerData(props) {
    const { userid } = useParams()
    const [address, setAddress] = useState("")
    const [isToastVisible, setIsToastVisible] = useState(false);


    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        libraries, // Reference the static `libraries` array
      });

    const handleAddressChange = address => {
        setAddress(address);
    };
    useEffect(() => {
        props.getSingleUserData(userid);
    }, []);

    const validationSchema = Yup.object().shape({
        firstname: Yup.string().required('Firstname is required'),
        lastname: Yup.string().required('Lastname is required'),
        email: Yup.string().required('Email is required').email('Email is invalid'),
        mobile: Yup.number().required('Mobile No. is required').typeError('Mobile No. is required'),
    });
    const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm({ resolver: yupResolver(validationSchema), mode: "all" });

    const data = props.fetchSingleUserDetails && props.fetchSingleUserDetails.data;
    useEffect(() => {
        setValue('firstname', data.firstname);
        setValue('lastname', data.lastname);
        setValue('userid', data.userid);
        setValue('email', data.email);
        setValue('mobile', data.mobile);
        setValue('accounttype', data.accounttype);
        setValue('city', data.city);
        setValue('state', data.state);
        setValue('country', data.country);
        setValue('address', data.address);
        setValue('postal_code', data.postal_code);
        setValue('corporate_code', data.corporate_code);
        setAddress(data.address);
    }, [data])


    // GeoLocation logic
    const handleSelect = address => {
        geocodeByAddress(address).then(results => getLatLng(results[0])).then(latLng => {
            const { lat, lng } = latLng
            const res = latiLongi(lat, lng)
            res.then(respon => {
                const address = respon.results[0].formatted_address;
                setAddress(address)
                setValue("address", address);
                setValue("latitude", lat);
                setValue("longitude", lng);
                let city, state, country, postCode;
                for (let i = 0; i < respon.results[0].address_components.length; i++) {
                    for (let j = 0; j < respon.results[0].address_components[i].types.length; j++) {
                        switch (respon.results[0].address_components[i].types[j]) {
                            case "locality":
                                city = respon.results[0].address_components[i].long_name;
                                setValue("city", city)
                                break;
                            case "administrative_area_level_1":
                                state = respon.results[0].address_components[i].long_name;
                                setValue("state", state)
                                break;
                            case "country":
                                country = respon.results[0].address_components[i].long_name;
                                setValue("country", country)
                                break;
                            case "postal_code":
                                postCode = respon.results[0].address_components[i].long_name;
                                setValue("postal_code", postCode)
                                break;
                            default:


                        }
                    }
                }
            })

        })
    }

    const onSubmit = async u_data => {
        props.updateCustomer(userid, u_data)
    }

    useEffect(() => {
        if (props.updateUser.statuscode === 200) {
            setIsToastVisible(true);
            toast.success('Customer Profile has been updated successfully', {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
                onClose: () => {
                    setIsToastVisible(false);
                    window.location.reload();
                },
            });
        }
    }, [props.updateUser]);
    return (
        <Container fluid className="pb-4">
            {isToastVisible && <div className="overlay" />}
            <ToastContainer
                position="top-center"
                autoClose={20}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                style={{ top: "10%", width: "auto", zIndex: "9999" }}
            />
            <PureBreadcrumbs />
            <div className="row">
                <div className="col-lg-12 col-sm-12 pad_t1">
                    <span style={{ color: "var(--text-primary)", fontWeight: "bold" }}>{t("Edit User Profile Details")} </span>
                </div>
            </div>

            <div className="row pad_t1 ">
                <div className="col-lg-12 col-sm-12">
                    <Card className="tariffcard">
                        <Card.Body>
                            <form autoComplete="off" onSubmit={e => e.preventDefault()}>
                                <div className="row">
                                    <div className="col-lg-4 col-sm-12">
                                        <div>
                                            <label htmlFor="floatingInputGridFirstname">{t("Firstname")} <span className='mandatory-field'>*</span></label>
                                            <input type="text" autoFocus className={`form-control gray-border ${errors.firstname ? 'is-invalid' : ''}`} name="firstName" id="floatingInputGridFirstname" placeholder={t("Firstname")} {...register("firstname")} />
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-sm-12">
                                        <div>
                                            <label htmlFor="floatingInputGridLastname">{t("Lastname")} <span className='mandatory-field'>*</span></label>
                                            <input type="text" className={`form-control gray-border ${errors.lastname ? 'is-invalid' : ''}`} name="lastname" id="floatingInputGridLastname" placeholder={t("Lastname")} {...register("lastname")} />
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-sm-12">
                                        <div>
                                            <label htmlFor="floatingInputGridUsername">{t("Username")}</label>
                                            <input type="text" className={`form-control gray-border ${errors.userid ? 'is-invalid' : ''}`} name="userid" id="floatingInputGridUsername" placeholder={t("Username")} {...register("userid")} disabled />
                                        </div>
                                    </div>
                                </div>

                                <div className="row pad_t1">
                                    <div className="col-lg-4 col-sm-12">
                                        <div>
                                            <label htmlFor="floatingInputGridEmail">{t("Email")} <span className='mandatory-field'>*</span></label>
                                            <input type="email" className={`form-control gray-border ${errors.email ? 'is-invalid' : ''}`} name="email" id="floatingInputGridEmail" placeholder={t("Email")}  {...register("email")} />
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-sm-12">
                                        <div>
                                            <label htmlFor="floatingInputGridMobile">{t("Mobile")}</label>
                                            <input type="tel" className={`form-control gray-border ${errors.mobile ? "is-invalid" : ''}`} name="mobile" id="floatingInputGridMobile" placeholder={t("Mobile")}  {...register("mobile")} />
                                            <div className="invalid-feedback">{errors.mobile?.message}</div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-sm-12">
                                        <div>
                                            <label htmlFor="floatingInputGridAccountType">{t("Account Type")}</label>
                                            <input type="text" className={`form-control gray-border ${errors.accounttype ? 'is-invalid' : ''}`} name="accounttype" id="floatingInputGridAccountType" placeholder={t("Account Type")}  {...register("accounttype")} disabled />
                                        </div>
                                    </div>
                                </div>
                                <div className="row pad_t1">
                                    <Col xs={12} md={12}>
                                        <div>
                                            {isLoaded ? (<PlacesAutocomplete
                                                value={address || ''}
                                                onChange={handleAddressChange}
                                                onSelect={handleSelect}
                                            >
                                                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                    <div>
                                                        <label>{t("Address")}</label>
                                                        {console.log(suggestions,"data")}
                                                        <input
                                                            {...getInputProps({
                                                                placeholder: 'Search Address ...',
                                                                className: "location-search-input gray-border",
                                                            })}
                                                        />
                                                        <div className="autocomplete-dropdown-container">
                                                            {loading && <div>Loading...</div>}
                                                            {suggestions?.map(suggestion => {
                                                                // inline style for demonstration purpose
                                                                const style = suggestion.active
                                                                    ? { backgroundColor: 'var(--button-text-color)', cursor: 'pointer' }
                                                                    : { backgroundColor: 'var(--button-text-color) ', cursor: 'pointer' };
                                                                return (
                                                                    <div className="input-suggestion"
                                                                        {...getSuggestionItemProps(suggestion, {

                                                                            style,
                                                                        })}
                                                                    >
                                                                        <span class="material-symbols-outlined"></span><span>{suggestion.description}</span>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                )}
                                            </PlacesAutocomplete>): (
                          <div>Loading Google Maps...</div>
                        )}
                                            {/* <input type="text" className={`form-control ${errors.address ? 'is-invalid' : ''}`} name="address" id="floatingInputGridname" placeholder="Enter the location address" {...register("address")} /> */}
                                            <div className="invalid-feedback">{errors.address?.message}</div>
                                        </div>
                                    </Col>
                                </div>

                                <div className="row pad_t1">
                                    <div className="col-lg-4 col-sm-12">
                                        <div>
                                            <label htmlFor="floatingInputGridCity">{t("City")}</label>
                                            <input type="text" className={`form-control gray-border ${errors.city ? 'is-invalid' : ''}`} name="city" id="floatingInputGridCity" placeholder={t("City")}  {...register("city")} />
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-sm-12">
                                        <div>
                                            <label htmlFor="floatingInputGridState">{t("State")}</label>
                                            <input type="text" className={`form-control gray-border ${errors.state ? 'is-invalid' : ''}`} name="state" id="floatingInputGridState" placeholder={t("State")}  {...register("state")} />
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-sm-12">
                                        <div>
                                            <label htmlFor="floatingInputGridCountry">{t("Country")}</label>
                                            <input type="text" className={`form-control gray-border ${errors.country ? 'is-invalid' : ''}`} name="country" id="floatingInputGridCountry" placeholder={t("Country")}  {...register("country")} />
                                        </div>
                                    </div>
                                </div>

                                <div className="row pad_t1">
                                    <div className="col-lg-4 col-sm-12">
                                        <div>
                                            <label htmlFor="floatingInputGridName">{t("Zipcode")}</label>
                                            <input type="text" className={`form-control gray-border ${errors.postal_code ? 'is-invalid' : ''}`} name="postal_code" id="floatingInputGridName" placeholder={t("Zipcode")}  {...register("postal_code")} />
                                        </div>
                                    </div>
                                    {/* <div class="col-lg-4 col-sm-12">
                                        <div>
                                            <label htmlFor="floatingInputGridName">Corporate Code</label>
                                            <input type="text" className={`form-control ${errors.corporate_code ? 'is-invalid' : ''}`} name="corporate_code" id="floatingInputGridCC" placeholder="Corporate Code"  {...register("corporate_code")} />
                                        </div>
                                    </div> */}
                                </div>
                            </form>
                            <Row className="mt-5 d-flex justify-content-end" >
                                <Col lg={6} md={12} className="d-flex justify-content-end" >
                                    {props.loading ?
                                        <button className="btn lgn-btn" type="button" disabled>
                                            <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                            &nbsp;
                                            {t("Saving...")}
                                        </button>
                                        : <button type="button" className="lgn-btn btn" onClick={handleSubmit(onSubmit)}>{t("Update Profile")}</button>
                                    }
                                </Col>
                            </Row>
                        </Card.Body>
                        <br />

                        <br /><br />
                    </Card>
                </div>
            </div>
        </Container>
    )
}

const mapStateToProps = function (state) {
    return {
        loading: state.customer.isLoading,
        fetchSingleUserDetails: state.customer.fetchsinglecutomers,
        updateUser: state.customer.updateCustomerProfile,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        getSingleUserData: (userid) => dispatch(singleCutomersAction(userid)),
        updateCustomer: (userid, u_data) => dispatch(updateCustomerProfile(userid, u_data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UpdateCustmomerData));

const latiLongi = (a, b) => {
    return Geocode.fromLatLng(a, b).then(res => { return res })
    // console.log("Gaadin",res); 
}