import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { Card, Row, Col, Container } from "react-bootstrap";
import {
  fetchRoleAction,
  fetchPrivilegeAction,
  fetchSubPrivilegeAction,
  fetchNewPrivilegeAction,
} from "../../../../store/actions/mainAction";
import { allOrgTariffAction } from "../../../../store/actions/organisationAction";
import { singleUserAction, updateAccessControllAction } from "../../../../store/actions/accessAction";
import PureBreadcrumbs from "../../breadcrums";
import "../access.css";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import Geocode from "react-geocode";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { Bounce, ToastContainer, toast } from "react-toastify";
import DarkThemeStyles from "../../resuableComponent/reactSelectSingleValueStyle";
import { useLoadScript } from "@react-google-maps/api";
import { Stepper } from "react-form-stepper";

var privilegeArray = [];
var menu = [];
const libraries = ["places"];
function UpdateAccess(props) {
  const { t } = useTranslation();
  const { userid } = useParams();
  const { role } = JSON.parse(localStorage.getItem("user"));
  const [address, setAddress] = useState("");
  // const [apiLoaded, setApiLoaded] = useState(false);
  const [error, setError] = useState(null);

  // useEffect(() => {
  //     // Define the initMap function that the Google Maps script will call
  //     window.initMap = () => {
  //         setApiLoaded(true);
  //     };

  //     // Check if the script is already loaded
  //     if (window.google && window.google.maps) {
  //         setApiLoaded(true);
  //     }
  // }, []);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries, // Reference the static `libraries` array
  });

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("Firstname is required"),
    last_name: Yup.string().required("Last Name is required"),
    userName: Yup.string()
      .required("Username is required")
      .min(6, "Username must be at least 6 characters")
      .max(20, "Username must not exceed 20 characters"),
    email: Yup.string().required("Email is required").email("Email is invalid"),
    address: Yup.string().required("Address is required"),
    phone: Yup.number().required("Phone number is required"),
    organisation: Yup.string().required("Organisation is required"),
    organisationType: Yup.string().required("Organisation Type is required"),
    city: Yup.string().required("City  is required"),
    state: Yup.string().required("State/Province  is required"),
    country: Yup.string().required("Country is required"),
    Zipcode: Yup.string().required("Zip code is required"),
    components: Yup.array().of(
      Yup.object().shape({
        userRole: Yup.string().nullable().required("User role is required"),
      })
    ),
  });

  useEffect(() => {
    props.getrole();
    props.allOrg();
    props.getSingleUserData(userid);
  }, []);

  const data = props.fetchSingleUserDetails && props.fetchSingleUserDetails.users;
  const accessData = data && data.access;
  // console.log("data: ", data)

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    formState: { errors, isValid },
  } = useForm({ resolver: yupResolver(validationSchema), mode: "all" });
  const [newRole, setNewRole] = useState("");
  const [newPrivilege, setNewPrivilege] = useState([]);
  const [newSubPrivilege, setSubNewPrivilege] = useState([]);
  const [formStep, setFormStep] = useState(0);
  const [globalPrivilageParentKey, setGlobalPrivilageParentKey] = useState("");
  const [selectedRegion, setSelectedRegions] = useState([]);
  const [redirect, setRedirect] = useState(false);
  const [isToastVisible, setIsToastVisible] = useState(false);

  useEffect(() => {
    if (data) {
      setValue("firstName", data.first_name);
      setValue("last_name", data.last_name);
      setValue("userName", data.userid);
      setValue("email", data.email);
      setValue("phone", data.phone);
      setValue("organisation", data.organisation, { shouldValidate: true });
      setValue("country", data.country);
      setValue("city", data.city);
      setValue("state", data.state);
      setValue("organisationType", data.organisation_type);
      setValue("Zipcode", data.postal_code);
      setValue("createdat", data.createdat);
      setValue("address", data.address, { shouldValidate: true });
      setAddress(data.address);
      register("address", data.address);
      setValue("components[0].userRole", data.role);
      data?.region?.map((region, i) => setSelectedRegions(region));
    }
  }, [data]);

  const handleAddressChange = (address) => {
    setAddress(address);
  };

  const [passwordType, setPasswordType] = useState("password");
  const [singleValue, setSingleVale] = useState("");

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const completeFormStep = () => {
    setFormStep((cur) => cur + 1);
  };
  const goToPrevStep = () => {
    setFormStep((cur) => cur - 1);
  };

  // Geolocation placeAutoComplete logic
  const handleSelect = async (address) => {
    try {
      const results = await geocodeByAddress(address);
      if (!results || results.length === 0) {
        throw new Error("No results found");
      }

      const latLng = await getLatLng(results[0]);
      const { lat, lng } = latLng;

      const res = await latiLongi(lat, lng);
      if (!res || !res.results || res.results.length === 0) {
        throw new Error("No response from latiLongi");
      }

      setError("");
      setAddress(address);
      setValue("address", address);
      setValue("latitude", lat);
      setValue("longitude", lng);

      let city, state, country, postCode, countryCode;
      res.results[0].address_components.forEach((component) => {
        component.types.forEach((type) => {
          switch (type) {
            case "locality":
              city = component.long_name;
              setValue("city", city);
              break;
            case "administrative_area_level_1":
              state = component.long_name;
              setValue("state", state);
              break;
            case "country":
              country = component.long_name;
              countryCode = component.short_name;
              setValue("country", country);
              break;
            case "postal_code":
              postCode = component.long_name;
              setValue("Zipcode", postCode);
              break;
            default:
              break;
          }
        });
      });
    } catch (error) {
      console.error("Error:", error);
      setError("Failed to retrieve location details. Please try again.");
    }
  };

  const prevRole = data.role;
  // console.log("prevRole",prevRole)

  const region = ["north", "south", "east", "west"];

  const options = region.map((region) => ({ value: region, label: region }));

  const handleRegionOnchange = (selectedOption) => {
    // console.log("selectedOption", selectedOption)
    if (selectedOption && selectedOption.length > 0) {
      const selectedValues = selectedOption.map((option) => option.value.toLowerCase());
      setSelectedRegions(selectedValues);
      // setSelectAll(selectedValues.includes("All"));
    } else {
      setSelectedRegions([]);
      // setSelectAll(false);
    }
  };

  //previous data is setting in this useEffect don't touch it
  useEffect(
    (e) => {
      if (singleValue === "" && prevRole !== "" && prevRole !== undefined) {
        props.get_new_privileges(
          prevRole === "Enterprise"
            ? "EP"
            : prevRole === "Admin"
              ? "SP"
              : prevRole === "Network Operator"
                ? "NP"
                : prevRole === "Regional Manager"
                  ? "RM"
                  : ""
        );
      }
      privilegeArray =
        accessData &&
        accessData.map((item) => ({
          ...item,
          menu: [],
        }));
      setNewPrivilege(privilegeArray);

      menu = accessData && accessData.flatMap(({ menu }) => menu);
      setSubNewPrivilege(menu);
    },
    [prevRole]
  );

  const handleOnPrivilegechange = (e) => {
    const target = e.target;
    let privilegeKey = target.id;
    const result =
      props.allNewPrivileges && props.allNewPrivileges.privileges.filter((pk) => pk.parent_key == privilegeKey);

    if (target.checked) {
      privilegeArray.push({
        parent_key: result[0].parent_key,
        value: result[0].value,
        icon: result[0].icon,
        menu: [],
        header: result[0].header,
      });
      setGlobalPrivilageParentKey(privilegeKey);
    } else {
      const removeIndex = privilegeArray.findIndex((item) => item.parent_key === privilegeKey);
      privilegeArray.splice(removeIndex, 1);
      setGlobalPrivilageParentKey("");
    }
    setNewPrivilege(privilegeArray);
  };

  const handleOnSubPrivilegechange = (e) => {
    const target = e.target;
    let subprivilegeKey = target.id;

    const result =
      props.allNewPrivileges &&
      props.allNewPrivileges.privileges.flatMap((privilege) => {
        return privilege.menu && privilege.menu.filter((subPrivilege) => subPrivilege.key === subprivilegeKey);
      });
    // console.table("sub-privilege result",result)

    if (target.checked) {
      menu.push({ parent_key: result[0].parent_key, key: result[0].key, value: result[0].value, icon: result[0].icon });
      // console.log("menu", menu)
    } else {
      const removeIndex = menu.findIndex((item) => item.key === subprivilegeKey);
      menu.splice(removeIndex, 1);
    }

    setSubNewPrivilege(menu);
  };

  const onSubmit = async (data) => {
    const uniqueKeys = new Set();
    const uniqueParentkey = new Set();

    const uniquePrivilageData =
      newPrivilege &&
      newPrivilege.reduce((acc, curr) => {
        if (!uniqueParentkey.has(curr.parent_key)) {
          uniqueParentkey.add(curr.parent_key);
          acc.push(curr);
        }
        return acc;
      }, []);

    const uniqueMenuData =
      newSubPrivilege &&
      newSubPrivilege.reduce((acc, curr) => {
        if (!uniqueKeys.has(curr.key)) {
          uniqueKeys.add(curr.key);
          acc.push(curr);
        }
        return acc;
      }, []);

    // console.log("uniquePrivilageData ", uniquePrivilageData)
    // console.log("uniqueMenuData", uniqueMenuData)

    let cartCopy = [...uniquePrivilageData];
    uniquePrivilageData.forEach((el) => {
      let submenu = [];
      uniqueMenuData.forEach((sel) => {
        if (el.parent_key === sel.parent_key) {
          submenu.push(sel);
        }
      });
      el.menu = submenu;
      // setNewPrivilege(uniquePrivilageData)
    });
    // console.log("new privilege state",newPrivilege)
    const emptysubPrivilege =
      uniquePrivilageData &&
      uniquePrivilageData.filter((privilege) => {
        return privilege.menu && privilege.menu.length === 0;
      });
    // console.table("empty sub-priv", emptysubPrivilege)

    const filterPrivilege =
      uniquePrivilageData &&
      uniquePrivilageData.filter((privilege) => {
        return privilege.menu && privilege.menu.length > 0;
      });

    const uniqueData = filterPrivilege.map((section) => {
      const uniqueMenuItems = Array.from(new Set(section.menu.map((item) => item.key))).map((key) =>
        section.menu.find((item) => item.key === key)
      );
      return { ...section, menu: uniqueMenuItems };
    });

    // console.table("uniqueData ", uniqueData);

    if (emptysubPrivilege.length > 0) {
      // console.log("emptysubPrivilege", emptysubPrivilege)
      alert("Please select the sub-privileges");
    } else if (uniqueData != "") {
      // console.log("data",selectedRegion, data, uniqueData)
      props.updateAccess(userid, selectedRegion, data, uniqueData);
    } else if (uniqueData.length === 0) {
      alert("Please select at-least One Privilege and sub-privileges");
    }
  };

  useEffect(() => {
    if (props.update.statuscode === 200) {
      setIsToastVisible(true);
      toast.success("Access has been updated", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
        onClose: () => {
          setIsToastVisible(false);
          setRedirect(true);
        },
      });
    }
  }, [props.update]);

  if (redirect) {
    return <Redirect to="/dashboard/view_access" />;
  }
  const renderButton = () => {
    if (formStep > 2) {
      return undefined;
    } else if (formStep === 1) {
      return (
        <div>
          <div className=" row p-3">
            <Col lg={6} md={6} className="d-flex justify-content-center justify-content-md-start mt-2">
              {isValid && (
                <button className="btn lgn-btn " type="button" onClick={goToPrevStep}>
                  {t("Back")}
                </button>
              )}
            </Col>
            {prevRole === "Regional Manager" ? (
              <Col lg={6} md={6} className="d-flex justify-content-center justify-content-md-end mt-2">
                {props.loading !== true ? (
                  <button
                    className="btn lgn-btn"
                    type="submit"
                    style={{
                      position: "static",
                    }}
                    onClick={handleSubmit(onSubmit)}
                    disabled={selectedRegion.length === 0}
                  >
                    {t("UPDATE USER")}
                  </button>
                ) : (
                  <button className="btn lgn-btn" type="button" disabled>
                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                    {t("Saving...")}
                  </button>
                )}
              </Col>
            ) : (
              <Col lg={6} md={6} className="d-flex justify-content-center justify-content-md-end mt-2">
                {props.loading !== true ? (
                  <button
                    className="btn lgn-btn"
                    type="submit"
                    style={{ position: "static" }}
                    onClick={handleSubmit(onSubmit)}
                    disabled={!isValid}
                  >
                    {t("UPDATE USER")}
                  </button>
                ) : (
                  <button className="btn lgn-btn" type="button" disabled>
                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                    {t("Saving...")}
                  </button>
                )}
              </Col>
            )}
          </div>
        </div>
      );
    } else {
      return (
        <div className="d-grid gap-2 d-md-flex justify-content-md-end p-3">
          <Col lg={6} className="d-flex justify-content-end">
            <button
              className="lgn-btn btn"
              type="button"
              onClick={completeFormStep}
              disabled={!isValid}
            >
              {t("PROCEED TO NEXT STEP")}
            </button>
          </Col>
        </div>
      );
    }
  };

  const isParentKeyChecked = (parentKey) => {
    return accessData && accessData.some((data) => data.parent_key === parentKey);
  };
  const isSubParentKeyChecked = (sub_parent_key) => {
    return accessData && accessData.some((data) => data.menu.some((sub_key) => sub_key.key === sub_parent_key));
  };

  const handleOrganisation = (e) => {
    const code = e.value;
    console.log("organisation", code);
    setValue("organisation", code);
  };

  const organisationOptions =
    props.organisationlist &&
    props.organisationlist.organisations?.map((item) => ({
      value: item.name,
      label: item.name,
    }));

  return (
    <Container fluid>
      {isToastVisible && <div className="overlay" />}
      <ToastContainer
        position="top-center"
        autoClose={20}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        style={{ top: "10%", width: "auto", zIndex: "9999" }}
      />
      <PureBreadcrumbs />
      <br />

      <div className=" tariffcard">
        {/* <!-- start step indicators --> */}
        {/* <div className="form-header d-flex mb-4">
          <span className="stepIndicator">{t("Update Details")}</span>
          <span className="stepIndicator">{t("Update Assign Roles")}</span>
        </div> */}
        <Stepper steps={[{ label: "Update Details" }, { label: "Update Assign Roles" }]} activeStep={formStep} />
        {/* <!-- end step indicators --> */}
      </div>

      <div className="row">
        <div className="col-lg-12 col-sm-12">
          <form autoComplete="off" onSubmit={(e) => e.preventDefault()}>
            {formStep === 0 && (
              <Card className="tariffcard">
                <Card.Body>
                  <div className="row">
                    <div className="col-lg-6 col-sm-12">
                      <div className="form-floating">
                        <label>
                          {t("First Name")} <span className="mandatory-field">*</span>
                        </label>
                        <input
                          type="text"
                          className={`form-control gray-border ${errors.firstName ? "is-invalid" : ""}`}
                          name="firstName"
                          id="floatingInputGridname"
                          placeholder={t("First Name")}
                          {...register("firstName")}
                        />
                        <div className="invalid-feedback">{errors.firstName?.message}</div>
                      </div>
                    </div>

                    <div className="col-lg-6 col-sm-12">
                      <div className="form-floating">
                        <label>
                          {t("Last Name")}
                          <span className="mandatory-field">*</span>
                        </label>
                        <input
                          type="text"
                          className={`form-control gray-border ${errors.last_name ? "is-invalid" : ""}`}
                          name="last_name"
                          id="floatingInputGridlast_name"
                          placeholder={t("Last Name")}
                          {...register("last_name")}
                        />
                        <div className="invalid-feedback">{errors.last_name?.message}</div>
                      </div>
                    </div>
                  </div>
                  <div className="row py-2">
                    <div className="col-lg-6 col-sm-12">
                      <div className="form-floating">
                        <label>
                          {t("Username")} <span className="mandatory-field">*</span>
                        </label>
                        <input
                          type="text"
                          disabled
                          className={`form-control gray-border ${errors.userName ? "is-invalid" : ""}`}
                          name="userName"
                          id="floatingInputGridusername"
                          placeholder={t("Username")}
                          {...register("userName")}
                        />
                        <div className="invalid-feedback">{errors.userName?.message}</div>
                      </div>
                    </div>

                    <div className="col-lg-6 col-sm-12">
                      <div className="form-floating">
                        <label>
                          {t("Temporary Password")} <span className="mandatory-field">*</span>
                        </label>
                        <input
                          disabled
                          type={passwordType}
                          className={`form-control gray-border ${errors.password ? "is-invalid" : ""}`}
                          name="password"
                          id="floatingInputGridpassword"
                          placeholder={t("Temporary Password")}
                          {...register("password")}
                        />
                        <span className="p-viewer">
                          <i className="btn" onClick={togglePassword}>
                            {passwordType === "password" ? <AiFillEyeInvisible /> : <AiFillEye />}
                          </i>
                        </span>
                        <div className="invalid-feedback">{errors.password?.message}</div>
                      </div>
                    </div>
                  </div>
                  <div className="row py-2">
                    <div className="col-lg-6 col-sm-12">
                      <div className="form-floating">
                        <label>
                          {t("Email")} <span className="mandatory-field">*</span>
                        </label>
                        <input
                          type="email"
                          className={`form-control  gray-border ${errors.email ? "is-invalid" : ""}`}
                          name="email"
                          id="floatingInputGridemail"
                          placeholder={t("Email")}
                          {...register("email")}
                        />
                        <div className="invalid-feedback">{errors.email?.message}</div>
                      </div>
                    </div>

                    <div className="col-lg-6 col-sm-12">
                      <div className="form-floating">
                        <label>
                          {t("Phone No.")} <span className="mandatory-field">*</span>
                        </label>
                        <input
                          type="number"
                          className={`form-control gray-border  ${errors.phone ? "is-invalid" : ""}`}
                          name="phone"
                          id="floatingInputGridphone"
                          placeholder={t("Phone No.")}
                          {...register("phone")}
                        />
                        <div className="invalid-feedback">{errors.phone?.message}</div>
                      </div>
                    </div>
                  </div>
                  <div className="row py-2">
                    <div className="col-lg-6 col-sm-12">
                      <div className="form-floating">
                        <label>
                          {t("Organisation")} <span className="mandatory-field">*</span>
                        </label>
                        <Select
                          options={organisationOptions ? organisationOptions : []}
                          className={`${errors.organisation ? "is-invalid" : ""}`}
                          name="organisation"
                          id="floatingInputGridorganisation"
                          placeholder={getValues("organisation") ? getValues("organisation") : "Select Organisation"}
                          styles={DarkThemeStyles}
                          menuPortalTarget={document.body}
                          onChange={handleOrganisation}
                        />
                        <div className="invalid-feedback">{errors.organisation?.message}</div>
                      </div>
                    </div>

                    <div className="col-lg-6 col-sm-12">
                      <div className="form-floating">
                        <label>
                          {t("Organisation Type")} <span className="mandatory-field">*</span>
                        </label>
                        <select
                          className={`form-select form-control ${errors.organisationType ? "is-invalid" : ""}`}
                          name="unit"
                          id="floatingSelectGridorganisationType"
                          {...register("organisationType")}
                        >
                          <option value="">{t("Select Organisation Type")}</option>
                          <option value="Limited Liability Company">{t("Limited Liability Company")}</option>
                          <option value="Co-operative Company">{t("Co-operative Company")}</option>
                          <option value="Unlimited Company">{t("Unlimited Company")}</option>
                        </select>
                        <div className="invalid-feedback">{errors.organisationType?.message}</div>
                      </div>
                    </div>
                  </div>

                  {/*Address */}
                  <div className="row g-2">
                    <div className="col-md px-3">
                      <div className="form-floating">
                        <label>{t("Enter Address")}</label>
                        {error && <div className="alert alert-danger">{error}</div>}
                        {isLoaded ? (
                          <PlacesAutocomplete
                            value={address}
                            onChange={(value) => (setValue("address", value), setAddress(value))}
                            onSelect={handleSelect}
                          >
                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                              <div>
                                <input
                                  {...getInputProps({
                                    placeholder: "Search Places ...",
                                    className: `location-search-input gray-border ${errors.address ? "is-invalid" : ""
                                      }`,
                                    style: { fontSize: 14, borderRadius: 2, height: 37 },
                                  })}
                                  name="address"
                                />
                                <div className="invalid-feedback">{errors.address?.message}</div>
                                <div className="autocomplete-dropdown-container">
                                  {loading && <div>Loading...</div>}

                                  {suggestions.map((suggestion, index) => {
                                    const className = suggestion.active ? "suggestion-item--active" : "suggestion-item";
                                    const style = suggestion.active
                                      ? { backgroundColor: "#fafafa", cursor: "pointer" }
                                      : { backgroundColor: "#ffffff", cursor: "pointer" };
                                    return (
                                      <div
                                        key={index}
                                        {...getSuggestionItemProps(suggestion, {
                                          className,
                                          style,
                                        })}
                                      >
                                        <span>{suggestion.description}</span>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            )}
                          </PlacesAutocomplete>
                        ) : (
                          <div>Loading Google Maps...</div>
                        )}
                      </div>
                    </div>
                  </div>
                  {/*Address */}

                  <div className="row py-2">
                    <div className="col-md">
                      <div className="form-floating">
                        <label>
                          {t("Country")} <span className="mandatory-field">*</span>
                        </label>
                        <input
                          type="text"
                          className={`form-control gray-border ${errors.country ? "is-invalid" : ""}`}
                          name="country"
                          id="floatingInputCountry"
                          placeholder={t("Country")}
                          {...register("country")}
                        />
                        <div className="invalid-feedback">{errors.country?.message}</div>
                      </div>

                      <div className="invalid-feedback">{errors.country?.message}</div>
                    </div>

                    <div className="col-md">
                      <div className="form-floating">
                        <div className="form-floating">
                          <label>
                            {t("State")} <span className="mandatory-field">*</span>
                          </label>
                          <input
                            type="text"
                            className={`form-control gray-border ${errors.state ? "is-invalid" : ""}`}
                            name="state"
                            id="floatingInputState"
                            placeholder={t("State")}
                            {...register("state")}
                          />
                          <div className="invalid-feedback">{errors.state?.message}</div>
                        </div>

                        <div className="invalid-feedback">{errors.state?.message}</div>
                      </div>
                    </div>
                    <div className="col-md">
                      <div className="form-floating">
                        <label>
                          {t("City")} <span className="mandatory-field">*</span>
                        </label>
                        <input
                          type="text"
                          className={`form-control gray-border ${errors.city ? "is-invalid" : ""}`}
                          name="city"
                          id="floatingInputCity"
                          placeholder={t("City")}
                          {...register("city")}
                        />
                        <div className="invalid-feedback">{errors.city?.message}</div>
                      </div>
                    </div>

                    <div className="col-md">
                      <div className="form-floating">
                        <label>
                          {t("Zipcode")} <span className="mandatory-field">*</span>
                        </label>
                        <input
                          type="text"
                          className={`form-control gray-border ${errors.Zipcode ? "is-invalid" : ""}`}
                          name="zipcode"
                          id="floatingInputzip"
                          placeholder={t("Zipcode")}
                          {...register("Zipcode")}
                        />
                        <div className="invalid-feedback">{errors.Zipcode?.message}</div>
                      </div>
                    </div>
                  </div>
                  <br></br>
                </Card.Body>
              </Card>
            )}
            {formStep === 1 && (
              <Card key={0}>
                <Card.Body>
                  <div className="row d-flex justify-content-between">
                    <div className="col-lg-12 col-sm-12">
                      <Card.Text style={{ fontSize: "14px", marginLeft: "1.2rem" }}>
                        <b>
                          {t("Roles")} <span className="mandatory-field">*</span>
                        </b>
                      </Card.Text>
                      <div className="ml-3 mr-3">
                        {role === "Admin" ? (
                          <div className="form-floating">
                            <select
                              disabled
                              className={`form-select form-control ${errors.components?.[0]?.userRole ? "is-invalid" : ""
                                }`}
                              name={`components[${0}].userRole`}
                              id="floatingSelectGridrole"
                              value={prevRole}
                              {...register(`components[${0}].userRole`)}
                            >
                              <option value={prevRole}>{prevRole}</option>
                              {/* {props.roles && props.roles.roles && props.roles.roles.map((item, index) => (
                                                                <option key={index} value={item.name}>{item.name}</option>
                                                            ))} */}
                            </select>
                          </div>
                        ) : (
                          <div className="form-floating">
                            <select
                              className={`form-select form-control ${errors.components?.[0]?.userRole ? "is-invalid" : ""
                                }`}
                              name={`components[${0}].userRole`}
                              id="floatingSelectGridrole"
                              value={prevRole}
                              {...register(`components.${0}.userRole`)}
                            >
                              <option value={prevRole}>{prevRole}</option>
                              {/* {props.roles && props.roles.roles && props.roles.roles.slice(2,).map((item, index) => (
                                                                <option key={index} value={item.name}>{item.name}</option>
                                                            ))} */}
                            </select>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <br />
                  {prevRole === "Regional Manager" && (
                    <Card style={{ marginLeft: "1rem", marginRight: "1rem" }}>
                      <Card.Body>
                        <label>
                          {t("Select Regions")} <span className="mandatory-field">*</span>
                        </label>
                        <Select
                          closeMenuOnSelect={true}
                          options={options || []}
                          isMulti
                          defaultValue={data?.region?.map((region) => ({ value: region, label: region }))}
                          onChange={handleRegionOnchange}
                          placeholder="Select Region"
                        />

                        {selectedRegion.length === 0 && (
                          <p className=" text-danger">Please select the region for Regional Manager</p>
                        )}
                      </Card.Body>
                    </Card>
                  )}
                  <div className="col-lg-12">
                    <Card key={1}>
                      <Card.Body>
                        <div>
                          <Row className=" d-flex justify-content-center">
                            <h4 style={{ textAlign: "center" }}>{t("Select Privileges and Sub Privileges")}</h4>
                          </Row>
                          <br />
                          <br />
                          <Row className="">
                            <div className="col-3 d-flex justify-content-center">
                              <h4>{t("Privileges")}</h4>
                            </div>
                            <div className="col-9 d-flex justify-content-around">
                              <h4>{t("Sub-Privileges")}</h4>
                            </div>
                          </Row>

                          {props.allNewPrivileges &&
                            props.allNewPrivileges.privileges &&
                            props.allNewPrivileges.privileges?.map((item, i) => (
                              <Row>
                                <>
                                  <Col lg={3}>
                                    <div className="my_Privilagecontainer">
                                      <div className="form-check form-switch" key={item.parent_key}>
                                        <input
                                          className={`form-check-input`}
                                          style={{ marginTop: "1.1rem" }}
                                          type="checkbox"
                                          id={item.parent_key}
                                          onChange={(e) => handleOnPrivilegechange(e)}
                                          defaultChecked={isParentKeyChecked(item.parent_key)}
                                        />
                                        <label
                                          style={{ marginLeft: "1rem", marginTop: "0.9rem" }}
                                          className="form-check-label"
                                          htmlFor={item.parent_key}
                                        >
                                          {item.value}
                                        </label>
                                      </div>
                                    </div>
                                  </Col>

                                  <Col lg={9}>
                                    <div className="my_SubPrivilagecontainer" id="subcontainer">
                                      {item.menu &&
                                        item.menu.map((sub_item, index) => (
                                          <div className="form-check form-switch mr-2 " key={sub_item.key}>
                                            &nbsp;
                                            <input
                                              className={`form-check-input`}
                                              type="checkbox"
                                              id={sub_item.key}
                                              onChange={(e) => handleOnSubPrivilegechange(e)}
                                              disabled={globalPrivilageParentKey !== sub_item.parent_key}
                                              defaultChecked={isSubParentKeyChecked(sub_item.key)}
                                            />
                                            &nbsp;
                                            <label
                                              style={{ marginLeft: "0.5rem" }}
                                              className="form-check-label"
                                              htmlFor={sub_item.key}
                                            >
                                              {sub_item.value}
                                            </label>
                                          </div>
                                        ))}
                                    </div>
                                  </Col>
                                </>
                              </Row>
                            ))}
                        </div>
                      </Card.Body>
                    </Card>
                  </div>
                </Card.Body>
              </Card>
            )}

            {renderButton()}
            <br />
            <br />
          </form>
        </div>
      </div>
    </Container>
  );
}

const mapStateToProps = (state) => {
  return {
    roles: state.main.roles,
    privilegelist: state.main.privileges,
    subprivilegelist: state.main.subprivileges,
    loading: state.access.isLoading,
    organisationlist: state.operator.organisationsList,
    fetchSingleUserDetails: state.access.singleuser,
    update: state.access.updateaccess,
    allNewPrivileges: state.main.getNewPrivilages,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getrole: () => dispatch(fetchRoleAction()),
    getprivileges: (role) => dispatch(fetchPrivilegeAction(role)),
    getsubprivileges: (role) => dispatch(fetchSubPrivilegeAction(role)),
    allOrg: () => dispatch(allOrgTariffAction()),
    getSingleUserData: (userid) => dispatch(singleUserAction(userid)),
    updateAccess: (userid, selectedRegion, savedata, privileges) =>
      dispatch(updateAccessControllAction(userid, selectedRegion, savedata, privileges)),
    get_new_privileges: (role) => dispatch(fetchNewPrivilegeAction(role)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(UpdateAccess);

const latiLongi = (a, b) => {
  return Geocode.fromLatLng(a, b).then((res) => {
    return res;
  });
  // console.log("Gaadin",res);
};
