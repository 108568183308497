
const initState = {
    isLoading: false,
    createtariff: '',
    list: '',
    singletariff: '',
    updatetariff: '',
    fetchcurrency: '',
    fetchchargingunit: '',
    fetchtarifftype: '',
    tariffpercentagechange: '',
    timeSlotCreate: '',
    timeSlotFetch: '',
    timeSlotUpdate: '',
    timeslotSingleFetch: '',
    fetchAllName: '',
    fetchActiveTariffName: '',
    error: '',
    saveTimeSlot: '',
    fetchAllTimeSlot: '',
    fetchSingleTimeSlot: '',
    updateTimeSlot: '',
    fetchStationName: '',
    activateTariff: '',
    deactivateTariff: '',
    activateTariffTimeSlot: '',
    deactivateTariffTimeSlot: '',
    fetchCountryCode: '',
    fetchAllStaticTariffData: '',
    fetchAllDataTimeSlot: '',
}
const tariffReducer = (state = initState, action) => {
    switch (action.type) {
        case 'LOAD_TARIFF':
            return { ...state, isLoading: true }
        case 'CREATE_TARIFF':
            return { ...state, createtariff: action.payload, isLoading: false };
        case 'LOAD_TARIFF_ALL':
            return { ...state, list: action.payload, isLoading: false };
        case 'TARIFF_ERROR':
            return { ...state, error: action.payload, isLoading: false };
        case 'LOAD_SINGLE_TARIFF':
            return { ...state, singletariff: action.payload, isLoading: false };
        case 'UPDATE_TARIFF':
            return { ...state, updatetariff: action.payload, isLoading: false };
        case 'FETCH_ALL_CURRENCY':
            return { ...state, fetchcurrency: action.payload, isLoading: false };
        case 'FETCH_ALL_CHARGING_UNIT':
            return { ...state, fetchchargingunit: action.payload, isLoading: false };
        case 'FETCH_ALL_TARIFF_TYPE':
            return { ...state, fetchtarifftype: action.payload, isLoading: false };
        case 'CREATE_TIMESLOT_PRICE':
            return { ...state, timeSlotCreate: action.payload, isLoading: false };
        case 'UPDATE_TIMESLOT_PRICE':
            return { ...state, timeSlotUpdate: action.payload, isLoading: false };
        case 'FETCH_TIMESLOT_PRICE_LIST':
            return { ...state, timeSlotFetch: action.payload, isLoading: false };
        case 'FETCH_TIMESLOT_PRICE':
            return { ...state, timeslotSingleFetch: action.payload, isLoading: false };
        case 'FETCH_NAME':
            return { ...state, fetchAllName: action.payload, isLoading: false };
        case 'FETCH_ACTIVE_TARIFF_NAME':
            return { ...state, fetchActiveTariffName: action.payload, isLoading: false };
        case 'TARIFF_PERCENTAGE_CHANGE':
            return { ...state, tariffpercentagechange: action.payload, isLoading: false };
        case 'SAVE_NEW_TARIFF_TIME_SLOT':
            return { ...state, saveTimeSlot: action.payload, isLoading: false };
        case 'FETCH_ALL_TARIFF_TIME_SLOT':
            return { ...state, fetchAllTimeSlot: action.payload, isLoading: false };
        case 'FETCH_SINGLE_TARIFF_TIME_SLOT':
            return { ...state, fetchSingleTimeSlot: action.payload, isLoading: false };
        case 'UPDATE_TIMESLOT':
            return { ...state, updateTimeSlot: action.payload, isLoading: false };
        case 'FETCH_TIMESLOT_STATION_NAME':
            return { ...state, fetchStationName: action.payload, isLoading: false };
        case 'ACTIVATE_TARIFF_API':
            return { ...state, activateTariff: action.payload, isLoading: false };
        case 'DEACTIVATE_TARIFF_API':
            return { ...state, deactivateTariff: action.payload, isLoading: false };
        case 'ACTIVATE_TARIFF_TIME_SLOT_API':
            return { ...state, activateTariffTimeSlot: action.payload, isLoading: false };
        case 'DEACTIVATE_TARIFF_TIME_SLOT_API':
            return { ...state, deactivateTariffTimeSlot: action.payload, isLoading: false };
        case 'FETCH_COUNTRY_CODE_API':
            return { ...state, fetchCountryCode: action.payload, isLoading: false };
        case 'FETCH_ALL_STATIC_TARIFF_DATA_REDUCER':
            return { ...state, fetchAllStaticTariffData: action.payload, isLoading: false };
        case 'FETCH_ALL_DATA_TIME_SLOT':
            return { ...state, fetchAllDataTimeSlot: action.payload, isLoading: false };
        default:
            return state;
    }
}
export default tariffReducer;