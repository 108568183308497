import React, { useMemo, useEffect, useState } from "react";
import Table from "../../reacttable/table";
import "../../reacttable/reactTable.css";
import { FaDownload, FaEdit, FaEye } from "react-icons/fa";
import { CSVLink } from "react-csv";
import { connect } from "react-redux";
import { Card, Col, Row } from "react-bootstrap";
import { activateTimeSlotTariff, deActivateTariffTimeSlot, fetchAllDataTimeSlot, fetchAllTimeSlot } from "../../../../store/actions/tariffAction";
import { TiTick } from "react-icons/ti";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import PureBreadcrumbs from "../../breadcrums";
import CustomReportPaginationTable from "../../report/adminReport/customReportPaginationTable";


const ViewTimeSlot = (props) => {
  const { t } = useTranslation();
  const document_count = props.fetchList && props.fetchList?.document_count;
  const defaultPageSize = 10;
  const pageCount = Math.ceil(document_count / defaultPageSize)
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredData, setFilteredData] = useState([]);
  const [search, setSearch] = useState('');
  const [TableData,setTableData] = useState("");

  useEffect(() => {
    props.getList(currentPage,10);
  }, [currentPage])


  const data = props.fetchList && props.fetchList.data;
  // console.log(data)

  useEffect(() => {
    if (search !== "" && TableData == "") {
      const id = setTimeout(() => {
        props.getAllData(1, document_count)
      }, 2000);
      return () => clearTimeout(id);
    }
  }, [search]);

  useEffect(()=>{
    if (props.getAllDataRes && props.getAllDataRes.data) {
      setTableData(props.getAllDataRes.data);
    }
  }, [props.getAllDataRes])

  const activateStation = (id) => {
    console.log(id);
    props.activateTariffTimeSlotApi(id);
  };

  if (props.activateTimeSlot.statuscode === 200) {
    window.location.reload();
  }

  const deactivateStation = (id) => {
    console.log(id);
    props.deactivateTariffTimeSlotApi(id);
  };

  if (props.deactivateTimeSlot.statuscode === 200) {
    window.location.reload();
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Plan Name',
        accessor: 'plan_name',
      },
      {
        Header: 'Day',
        accessor: 'day',
        Cell: (day) => {
           const item = day.item.timeslot[0]
          return (
            <div>
              <span>{item.day}</span>
            </div>
          )
        }
      },
      {
        Header: 'Start Time',
        accessor: 'start_time',
        Cell: (day) => {
          const item = day.item.timeslot[0]
         return (
           <div>
             <span>{item.start_time}</span>
           </div>
         )
       }
      },
      {
        Header: 'End Time',
        accessor: 'end_time',
        Cell: (day) => {
          const item = day.item.timeslot[0]
         return (
           <div>
             <span>{item.end_time}</span>
           </div>
         )
       }
      },
      {
        Header: 'Tariff Name',
        accessor: 'tariff_name',
        Cell: (day) => {
          const item = day.item.timeslot[0]
         return (
           <div>
             <span>{item.end_time}</span>
           </div>
         )
       }
      },
      {
        Header: 'Station Name',
        accessor: 'station_name',
      },
      {
        Header: 'Role',
        accessor: 'role',
      },
      {
        Header: 'Operator',
        accessor: 'operator',
      },
      {
        Header: "Active/Inactive",
        accessor: "status",
        Cell: (status) => {
          return (
            <div>
              <span>
                {status.cell === "Active" ? (
                  <span className="text-success">Active</span>
                ) : (
                  <span className="text-danger">Inactive</span>
                )}
              </span>
            </div>
          );
        },
      },
      {
        Header: "Actions",
        accessor: "id",
        Cell: (cell) => {
          return (
            <div>
              <span>
                <a
                  href={`/dashboard/view_time_slot/view_single_time_slot/${cell.cell}`}
                  rel="tooltip"
                  title="View"
                >
                  <FaEye />
                </a>
              </span>
              &nbsp;&nbsp;
              <span>
                <a
                  href={`/dashboard/update_time_slot/${cell.cell}`}
                  rel="tooltip"
                  title="Edit"
                >
                  <FaEdit />
                </a>
              </span>
              &nbsp;&nbsp;  &nbsp;&nbsp;
              <span style={{ cursor: "pointer" }}>
                <TiTick
                  onClick={() => activateStation(cell.cell)}
                  title="Activate"
                />
              </span>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <span style={{ cursor: "pointer" }}>
                <AiOutlineCloseCircle
                  onClick={() => deactivateStation(cell.cell)}
                  title="De-Activate"
                />
              </span>
            </div>
          );
        },
      },
    ],
    []
  );

  const initialVisibleColumns = [
    "plan_name",
    "day",
    "start_time",
    "end_time",
    "station_name",
    "tariff_name",
    "role",
    "operator",
    "status",
    "id"
  ]

  const headers = [

    { label: 'Plan Name', key: 'plan_name' },
    { label: 'Tariff Name', key: 'timeslot[0].tariff_name' },
    { label: 'Day', key: 'timeslot[0].day' },
    { label: 'Time From', key: 'timeslot[0].start_time' },
    { label: 'Time To', key: 'timeslot[0].end_time' },
    { label: 'Role', key: 'role' },
    { label: 'Operator', key: 'operator' },

  ];

  return (
    <div className="container-fluid">
      <Row>
        <PureBreadcrumbs />
      </Row>
      <Row className="mt-5">
        <Col>
          <Card className="customercard">
            <div className="table-header">{t("Tariff Time Slot List")}</div>
            <Card.Body>
              {props.loading ? <h3 style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "250px",
              }}>Loading data...</h3> :
                data && data.length > 0 ? (
                  <div>
                    <div style={{ display: 'flex', justifyContent: 'end' }}>

                      <button className="csv-file-btn">
                        <CSVLink
                          className="fas"
                          data={data}
                          headers={headers}
                          filename="Time-SlotTariff-List.csv"
                          style={{ textDecoration: 'none' }}
                        >
                          <FaDownload className="download-icon" />
                        </CSVLink>
                      </button>
                    </div>
                    <CustomReportPaginationTable
                                data={data}
                                allData={TableData}
                                pageCount={pageCount}
                                columns={columns}
                                search={search}
                                setSearch={setSearch}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                pageSize={pageSize}
                                setPageSize={setPageSize}
                                filteredData={filteredData}
                                setFilteredData={setFilteredData}
                                loading={props.loading}
                                initialVisibleColumns={initialVisibleColumns}
                                t={t}
                            />
                    {/* <Table data={data} columns={columns}></Table> */}
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  > <h3>{t("No Data Found")}</h3>
                  </div>
                )}
            </Card.Body>
          </Card>
        </Col>
      </Row>

    </div>
  );

}
const mapStateToProps = (state) => {
  return {
    loading: state.tariff.isLoading,
    fetchList: state.tariff.fetchAllTimeSlot,
    activateTimeSlot: state.tariff.activateTariffTimeSlot,
    deactivateTimeSlot: state.tariff.deactivateTariffTimeSlot,
    getAllDataRes: state.tariff.fetchAllDataTimeSlot
  }
}
const mapDispatchToProps = dispatch => {
  return {
    getList: (currentPage, pageSize) => dispatch(fetchAllTimeSlot(currentPage, pageSize)),
    getAllData: (currentPage, pageSize) => dispatch(fetchAllDataTimeSlot(currentPage, pageSize)),
    activateTariffTimeSlotApi: (id) => dispatch(activateTimeSlotTariff(id)),
    deactivateTariffTimeSlotApi: (id) => dispatch(deActivateTariffTimeSlot(id)),

  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ViewTimeSlot)
