import React from "react";
import { useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { bootNotificationAction } from "../../../../store/actions/evseAction";
import { Container, Row, Col, Card } from "react-bootstrap";
import PureBreadcrumbs from "../../breadcrums";
import Table from "../../reacttable/table";
import { useTranslation } from "react-i18next";

function BootNotification(props) {
  const { t } = useTranslation();
  useEffect(() => {
    props.all("All");
  }, []);

  const data = props.list.data && props.list.data;
  // console.log("data", data);
  // const dynamicicon = (status) => {
  //     if (status == "Available") {
  //         return "green"
  //     } else if (status == "In use") {
  //         return "blue"
  //     } else if (status == "Maintenance") {
  //         return "red"
  //     } else if (status == "Out of order") {
  //         return "red"
  //     } else {
  //         return "grey"
  //     }
  // }

  const columns = useMemo(
    () => [
      {
        Header: "EVSE ID",
        accessor: "evse_id",
      },
      {
        Header: "Charger Serial Number",
        accessor: "chargeBoxSerialNumber",
      },
      {
        Header: "Date",
        accessor: "date_time",
        Cell:(props) => <div>{props.value.split("+")[0]}</div>,
      },
      {
        Header: "Charge Point Model",
        accessor: "chargePointModel",
      },
      {
        Header: "Charge Point Serial Number",
        accessor: "chargePointSerialNumber",
      },
      {
        Header: "Charge Point Vendor",
        accessor: "chargePointVendor",
      },
      {
        Header: "Firmware Version",
        accessor: "firmwareVersion",
      },
      {
        Header: "ICCID",
        accessor: "iccid",
      },
      {
        Header: "Imsi",
        accessor: "imsi",
      },
      {
        Header: "Meter Serial Number",
        accessor: "meterSerialNumber",
      },
      {
        Header: "Meter Type",
        accessor: "meterType",
      },
    ],
    []
  );

  return (
    <Container fluid>
      <PureBreadcrumbs />
      <br />

      <Row className="mt-4">
        <Col>
          <Card className="customercard">
            <div className="table-header">{t("View Boot Notification Details")}</div>
            {props.loading ? <h3 style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "450px",
            }}>Loading data...</h3> :
              data && data.length > 0 ? (
                <div>
                  {" "}
                  <Table data={data} columns={columns}></Table>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h3 className="loader">{t("No data found")}</h3>
                </div>
              )}
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.evse.isEvseLoading,
    list: state.evse.bootnotify,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    all: () => dispatch(bootNotificationAction()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(BootNotification);
