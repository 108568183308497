import React, { useEffect, useMemo, useState } from "react";
import { Row, Col, Card, Container } from "react-bootstrap";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import Table from "../../reacttable/table";
import moment from "moment";
import DownloadRfid from "../../evse/viewRfid/downloadRfid";
import DownloadBindRfidDetails from "./downloadBindRfidDetails";
import CustomReportPaginationTable from "../../report/adminReport/customReportPaginationTable";
import { allRFIDAction, fetchAllBindedRfid, fetchRfidList } from "../../../../store/actions/customerActions";

function RfidList(props) {
    const { t } = useTranslation();
    const document_count = props.fetchRfidListRes && props.fetchRfidListRes?.document_count;
    const defaultPageSize = 10;
    const pageCount = Math.ceil(document_count / defaultPageSize)
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [filteredData, setFilteredData] = useState([]);
    const [search, setSearch] = useState('');
    const [TableData,setTableData] = useState("");
    // console.log("props", props);
    
    useEffect(() => {
        props.getRfid(currentPage, 10)
    }, [currentPage])
    const data = props.fetchRfidListRes && props.fetchRfidListRes.data;
    
    
    
    useEffect(() => {
        if (search !== "" && TableData == "") {
          const id = setTimeout(() => {
              props.all(1, document_count)
            }, 2000);
            return () => clearTimeout(id);
        }
      }, [search]);
      
 
      useEffect(()=>{
        if (props.rfidList && props.rfidList.data) {
          setTableData(props.rfidList.data);
        }
      }, [props.rfidList])
      
    const columns = useMemo(
        () => [
            {
                Header: "Rfid",
                accessor: "rfid",
            },
            {
                Header: "User Name",
                id: "useridd",
                accessor: "userid",
            },
            {
                Header: "Binded Station Name",
                accessor: "station",
                Cell: (cell) => {
                    const station = cell.cell;
                    const rfidStations = cell.cell;
                    if (station === 'All') {
                        return 'All';
                    } else if (Array.isArray(rfidStations) && rfidStations.length > 0) {
                        return rfidStations.join(', ');
                    } else {
                        return '';
                    }
                }
            },
            {
                Header: "Is Free Charging?",
                accessor: "free_charging",
                Cell: (free_charging) => {
                    return (
                        <div>
                            <span>
                                {free_charging.value ? (
                                    <span className="text-success">Yes</span>
                                ) : (
                                    <span className="text-danger">No</span>
                                )}
                            </span>
                        </div>
                    );
                },
            },
            {
                Header: "Created Date",
                id: "registerat",
                accessor: "registerat",
               Cell: (d) => {
                    return moment(d.registerat).format("DD-MM-YYYY");
                },
            },

        ],
        []
    );

    const initialVisibleColumns = [
        "rfid",
        "userid",
        "station",
        "free_charging",
        "registerat",
      ]
        

    return (
        <div>
            <div className="customercard mt-2">
                <Row>
                    <Col className="d-flex justify-content-end">
                        <DownloadBindRfidDetails {...props} data={data} />
                    </Col>
                </Row>
                {props.loading ? <h3 style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "250px",
                }}>{t("Loading data...")}</h3> :
                    props.fetchRfidListRes && props.fetchRfidListRes?.data && props.fetchRfidListRes?.data.length !== null  ? (
                        <div>
                             <CustomReportPaginationTable
                                data={data}
                                allData={TableData}
                                pageCount={pageCount}
                                columns={columns}
                                search={search}
                                setSearch={setSearch}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                pageSize={pageSize}
                                setPageSize={setPageSize}
                                filteredData={filteredData}
                                setFilteredData={setFilteredData}
                                loading={props.loading}
                                initialVisibleColumns={initialVisibleColumns}
                                t={t}
                            />
                            {/* <Table data={data} columns={columns} /> */}
                        </div>
                    ) : (
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <h3>{t("No Data Found")}</h3>
                        </div>
                    )}
            </div>

        </div >
    )
}

const mapStateToProps = (state) => {
    return {
        loading: state.customer.isLoading,
        fetchRfidListRes: state.customer.fetchRfidList,
        rfidList: state.customer.fetchAllBindRfid,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getRfid: (currentPage, pageSize) => dispatch(fetchRfidList(currentPage, pageSize)),
        all:(currentPage, pageSize) => dispatch(fetchAllBindedRfid(currentPage, pageSize)),
    };

};
export default connect(mapStateToProps, mapDispatchToProps)(RfidList);
