import React from 'react';
import jsPDF from "jspdf";
import "jspdf-autotable";
import "./pdf.css"
import { useTranslation } from 'react-i18next';

// Create Document Component
const MyRevenueDocument = (props) => {
  const { allSortedData } = props;
  const { t } = useTranslation();
  // console.log("props from revenue document", props)
  const generatePDF = () => {
    // console.log("generatePDF")
    //   const unit = "pt";
    // const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape
    const marginLeft = 15;
    const doc = new jsPDF(orientation);
    doc.setFontSize(12);
    const range = "Date range: " + props.start.toLocaleString() + " to " + props.end.toLocaleString()

    const headers = [["Location", "Operator", "City", "Per Unit Kwh", "Total Amount", "Unit Consumed", "Client Revenue", "Self Revenue"]];
    
    const data = allSortedData && allSortedData.map(item => [item.location, item.operator, item.city, item.per_unit_kwh, item.total_amount, item.kwh, item.client_revenue_share, item.self_revenue_share]);
    let content = {
      startY: 30,
      head: headers,
      body: data
    };

    doc.text("Revenue Report", marginLeft, 10); 
    doc.text(range, marginLeft, 20);
    doc.autoTable(content);
    doc.save(props.start.toISOString().substr(0, 10) + "_" + props.end.toISOString().substr(0, 10) + `.pdf`)
  }


  return (
    <div>
      <button className='pdfbutton' onClick={generatePDF} disabled={!allSortedData.length > 0} type="button">{t("PDF(Admin)")}</button>
    </div>
  )
}

export default MyRevenueDocument;