import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import PureBreadcrumbs from "../../breadcrums";
import { Container, Card, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Table from "../../reacttable/table";
import { FaEdit, FaEye } from "react-icons/fa";
import { TiTick } from "react-icons/ti";
import { AiOutlineCloseCircle } from "react-icons/ai";
import {
  activateSubscriptionApi,
  deactivateSubscriptionApi,
  fetchAllSubscriptionApi,
  fetchAllSubscriptionData,
} from "../../../../store/actions/walletActions";
import CustomReportPaginationTable from "../../report/adminReport/customReportPaginationTable";

export const ViewSubscription = (props) => {
  const [TableData,setTableData] = useState("");
  const document_count = props.fetchAllRes && props.fetchAllRes?.document_count;
  const defaultPageSize = 10;
  const pageCount = Math.ceil(document_count / defaultPageSize)
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredData, setFilteredData] = useState([]);
  const [search, setSearch] = useState('');
  const { t } = useTranslation();


  useEffect(() => {
    props.fetchlimit(currentPage,10);
  }, [currentPage]);

  const data = props.fetchAllRes && props.fetchAllRes.data;
  // console.log(data,"data");
  const total = data && data.length;
  const active = data && data.filter((item) => item.status === "Active").length;
  const inActive = data && data.filter((item) => item.status === "Inactive").length;
  const draft = data && data.filter((item) => item.status === "Draft").length;

  useEffect(() => {
    if (search !== "" && TableData == "") {
      const id = setTimeout(() => {
        props.fetchAllData(1, document_count)
      }, 2000);
      return () => clearTimeout(id);
    }
  }, [search]);

  useEffect(()=>{
    if (props.fetchAllDataRes && props.fetchAllDataRes.data) {
      setTableData(props.fetchAllDataRes.data);
    }
  }, [props.fetchAllDataRes])


  const activateSubscription = (sub_name, sub_type, duration) => {
    // console.log(sub_name);
    // console.log(sub_type);
    // console.log(duration);
    props.activate(sub_name, sub_type, duration);
  };

  if (props.activateRes.statuscode === 200) {
    window.location.reload();
  }

  const deactivateSubscription = (e) => {
    // console.log(e);
    props.deactivate(e);
  };

  if (props.deactivateRes.statuscode === 200) {
    window.location.reload();
  }

  const columns = useMemo(
    () => [
      {
        Header: "Subscription Name",
        id: "sub_name1",
        accessor: "sub_name",
      },
      {
        Header: "Subscription Type",
        accessor: "sub_type",
      },
      {
        Header: "Subscription Value",
        accessor: "sub_value",
      },
      {
        Header: "Subscription Duration",
        accessor: "sub_duration",
      },
      {
        Header: "Recharge Amount",
        accessor: "recharge_amount",
      },
      {
        Header: "Create Date",
        accessor: "createdat",
        Cell: (props) => {
          return (
            <div>
              <span>{props.cell.split("T")[0]}</span>
            </div>
          );
        }
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: (cell) => {
          return (
            <div>
              <span>
                {cell.cell === "Active" ? (
                  <span className="text-success">{t("Active")}</span>
                ) : cell.cell === "Inactive" ? (
                  <span className="text-danger">{t("Inactive")}</span>
                ) : (
                  <span className="text-warning">{t("Draft")}</span>
                )}
              </span>
            </div>
          );
        },
      },
      {
        Header: "Actions",
        accessor: "sub_name",
        Cell: (cell) => {
          const { item } = cell;
          const status = item.status;
          const sub_name = item.sub_name;
          const sub_type = item.sub_type;
          const duration = item.sub_duration;
          
          return (
            <div>
              <span>
                <a href={`/dashboard/view_subscription/viewSingleSubscription/${sub_name}`} rel="tooltip" title="View">
                  <FaEye />
                </a>
              </span>
              &nbsp;
              {status && status === "Draft" ? (
                <span>
                  <a href={`/dashboard/view_subscription/updateSubscription/${sub_name}`} rel="tooltip" title="Edit">
                    <FaEdit />
                  </a>
                </span>
              ) : (
                ""
              )}
              &nbsp;
              <span style={{ cursor: "pointer" }}>
                <TiTick onClick={() => activateSubscription(sub_name, sub_type, duration)} title="Activate" />
              </span>
              &nbsp;
              <span style={{ cursor: "pointer" }}>
                <AiOutlineCloseCircle
                  onClick={() => deactivateSubscription(sub_name, sub_type, duration)}
                  title="De-Activate"
                />
              </span>
            </div>
          );
        },
      },
    ],
    []
  );

  const initialVisibleColumns = [
    "sub_name",
    "sub_type",
    "sub_value",
    "sub_duration",
    "recharge_amount",
    "status",
    "actions",
    "createdat",
  ]

  return (
    <Container fluid className="pb-4">
      <PureBreadcrumbs />
      <Row className="mt-2">
        <Col className="col-md-3 col-12">
          <Card className="customercard card-custom total-rules cards">
            <div className="diagonal-line"></div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <p
                style={{
                  backgroundColor: "blue",
                  padding: "3px",
                  borderRadius: "50%",
                  width: "8px",
                  height: "8px",
                  marginRight: "8px",
                }}
              ></p>
              <p>{t("Total subscriptions")}</p>
            </div>
            <Card.Title>
              <b>{total ? total : 0}</b>
            </Card.Title>
          </Card>
        </Col>

        <Col className="col-md-3 col-12">
          <Card className="customercard card-custom total-active-rules cards">
            <div className="diagonal-line"></div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <p
                style={{
                  backgroundColor: "orange",
                  padding: "3px",
                  borderRadius: "50%",
                  width: "8px",
                  height: "8px",
                  marginRight: "8px",
                }}
              ></p>
              <p>{t("Total active subscriptions")}</p>
            </div>
            <Card.Title>
              <b>{active ? active : 0}</b>
            </Card.Title>
          </Card>
        </Col>

        <Col className="col-md-3 col-12">
          <Card className="customercard card-custom total-inactive cards">
            <div className="diagonal-line"></div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <p
                style={{
                  backgroundColor: "red",
                  padding: "3px",
                  borderRadius: "50%",
                  width: "8px",
                  height: "8px",
                  marginRight: "8px",
                }}
              ></p>
              <p>{t("Total inactive subscriptions")}</p>
            </div>
            <Card.Title>
              <b>{inActive ? inActive : 0}</b>
            </Card.Title>
          </Card>
        </Col>
        <Col className="col-md-3 col-12">
          <Card className="customercard card-custom total-draft cards">
            <div className="diagonal-line"></div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <p
                style={{
                  backgroundColor: "grey",
                  padding: "3px",
                  borderRadius: "50%",
                  width: "8px",
                  height: "8px",
                  marginRight: "8px",
                }}
              ></p>
              <p>{t("Total draft subscriptions")}</p>
            </div>
            <Card.Title>
              <b>{draft ? draft : 0}</b>
            </Card.Title>
          </Card>
        </Col>
      </Row>

      <Row className="mt-2">
        <Col lg={12}>
          <Card className="mt-2 customercard ">
            <div className="table-header">{t("View Subscription")}</div>

            <Card.Body>
              <div className="evse">
                {props.loading ? (
                  <h3
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "250px",
                    }}
                  >
                    Loading data...
                  </h3>
                ) : props.fetchAllRes && props.fetchAllRes.data != null ? (
                  <div>
                      <CustomReportPaginationTable
                                data={data}
                                allData={TableData}
                                pageCount={pageCount}
                                columns={columns}
                                search={search}
                                setSearch={setSearch}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                pageSize={pageSize}
                                setPageSize={setPageSize}
                                filteredData={filteredData}
                                setFilteredData={setFilteredData}
                                loading={props.loading}
                                initialVisibleColumns={initialVisibleColumns}
                                t={t}
                            />
                    {/* <Table data={data} columns={columns}></Table> */}
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "250px",
                    }}
                  >
                    <h3>{t("No Data Found")}</h3>
                  </div>
                )}
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.wallet.isLoading,
    fetchAllRes: state.wallet.fetchAllSub,
    activateRes: state.wallet.activateSub,
    deactivateRes: state.wallet.deactivateSub,
    fetchAllDataRes: state.wallet.fetchsuballdata,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchlimit: (currentPage, pageSize) => dispatch(fetchAllSubscriptionApi(currentPage, pageSize)),
    fetchAllData: (currentPage, pageSize) => dispatch(fetchAllSubscriptionData(currentPage, pageSize)),
    activate: (sub_name) => dispatch(activateSubscriptionApi(sub_name)),
    deactivate: (sub_name) => dispatch(deactivateSubscriptionApi(sub_name)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewSubscription);
