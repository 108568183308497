import React, { useEffect } from "react";
import { connect } from "react-redux";
import { fetchGraphApi } from "../../../../store/actions/advertisementAction";
import ApexCharts from "react-apexcharts";
import { useTranslation } from "react-i18next";


function AddGraph(props) {
  const { t } = useTranslation();
  useEffect(() => {
    props.graphData();
  }, []);

  const graphData = props.sixMonthGraphApi.tdata;
  let monthpush = [];

  const ps_month = props.sixMonthGraphApi.tdata && props.sixMonthGraphApi.tdata.map((item) => {

    if (item.number === 1) {
      monthpush.push("January");

    } else if (item.number === 2) {
      monthpush.push("February");

    } else if (item.number === 3) {
      monthpush.push("March");

    } else if (item.number === 4) {
      monthpush.push("April");

    } else if (item.number === 5) {
      monthpush.push("May");

    } else if (item.number === 6) {
      monthpush.push("June");

    } else if (item.number === 7) {
      monthpush.push("July");

    } else if (item.number === 8) {
      monthpush.push("August");

    } else if (item.number === 9) {
      monthpush.push("September");

    } else if (item.number === 10) {
      monthpush.push("October");

    } else if (item.number === 11) {
      monthpush.push("November");

    }
    else if (item.number === 12) {
      monthpush.push("December");

    }
  });
  // console.log("month", monthpush)

  const options = {
    chart: {
      type: "bar",
      height: 350,
      stacked: true,
      colors: ["#FFD700", "#D3D3D3"], // Define colors for each series
    },
    plotOptions: {
      bar: {
        horizontal: false,
        barWidth: "50%",
        borderRadius: 8,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 1,
      colors: ["#fff"],
    },
    xaxis: {
      categories: monthpush,
    },
    yaxis: {
      title: {
        text: "Number of Advertisements",
      },
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val;
        },
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 0.25,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100],
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
      offsetX: 40,
    },
  };


  return (
    <>

      <div>
        {props.sixMonthGraphApi.tdata &&
          props.sixMonthGraphApi.tdata.length > 0 ? (
          <ApexCharts
            options={options}
            series={[
              {
                name: "Total Advertisement",
                data: props.sixMonthGraphApi?.tdata?.map(item => item.total_advertisement),
              },
              {
                name: "Draft Advertisement",
                data: props.sixMonthGraphApi?.tdata?.map(item => item.draft_advertisement),
              },
              {
                name: "Lapsed Advertisement",
                data: props.sixMonthGraphApi?.tdata?.map(item => item.lapsed_advertisement),
              },
            ]}
            type="bar"
            height={400}
          />
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "400px",
              // width : "300px",
            }}
          >
            {props.loading ? <h3>Loading data...</h3> : <h3>{t("No data found")}</h3>}
          </div>
        )}
      </div>
    </>
  )
}
const mapStateToProps = (state) => {
  return {
    loading: state.advertise.isLoading,
    sixMonthGraphApi: state.advertise.fetch_six_month_graph_api,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    graphData: () => dispatch(fetchGraphApi()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddGraph);